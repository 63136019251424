import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_PURCHASE_GA4}`;

/**
 * ga4 商品category L1 L2 L3與brand
 * @param {string[]} ids product_guids
 * @returns Axios
 */
// :product_guids, ex: 100B2000007806009,100B2000007807006
export default (ids) =>
  axios.get(`${API_PATH}/${ids.join(',')}`).catch(() => {
    // ga error 不應影響正常程式執行，此處先 catch / mock 起來
    return { data: [] };
  });
