<template>
  <swiper v-if="!isEmptyValue(slides) && slides.length > 1" ref="swiperBigSlide" class="swiper swiperBigSlide" :options="swiperBigSlideOptions">
    <swiper-slide v-for="(item, index) in resetSlides" :key="`type-2-${index}`" class="swiper-slide">
      <router-link-usage :id="`home-bbanner-${index + 1}`" :link="item.link" :title="item.alt">
        <img class="swiper-lazy" :src="item.image" :alt="item.alt" :fetchpriority="index === 0 ? 'high' : 'low'" />
      </router-link-usage>
    </swiper-slide>
    <div v-show="!isCorrected" slot="pagination" class="swiper-pagination"></div>
  </swiper>
  <div v-else-if="slides.length === 1">
    <template v-for="(item, index2) in slides" :key="`type-2-${index2}`">
      <router-link-usage :id="`home-bbanner-${index2 + 1}`" :link="item.link" :title="item.alt">
        <img class="tw-w-full swiper-lazy" :src="item.image" :alt="item.alt" fetchpriority="high" />
      </router-link-usage>
    </template>
  </div>
  <div v-else class="loading logo-loading">&nbsp;</div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { isEmptyValue } from '@/helper/data-process';
import { adjustArray } from '@/helper/grid';
import { modifySwiperOptionForVisualTest } from '@/helper/swiper';

const homeAdStore = useHomeAd2024Store();
const { getBigSlide } = storeToRefs(homeAdStore);
const slides = computed(() => getBigSlide.value?.items || []);
/** @const {computed} resetSlides 若 slides 數量不夠時補足 */
const resetSlides = computed(() => (slides.value.length > 3 ? slides.value : adjustArray(slides.value, 2)));
/** @const {computed} isCorrected 輪播數量不足時是否補正 */
const isCorrected = computed(() => (slides.value.length > 3 ? false : slides.value.length !== resetSlides.value.length));

const swiperBigSlide = ref(null);
const swiperBigSlideOptions = ref(
  modifySwiperOptionForVisualTest({
    loop: true,
    autoplay: { delay: 3000, disableOnInteraction: false },
    centeredSlides: true,
    slidesPerView: 1.12,
    spaceBetween: 0,
    slideToClickedSlide: true,
    pagination: { el: '.swiperBigSlide .swiper-pagination', clickable: true },
  }),
);
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.swiperBigSlide {
  @apply tw-w-full tw-mt-4 tw-mb-4;
}
.swiper-slide-prev,
.swiper-slide-next {
  transform: scale(0.86) !important;
}

.swiper-slide-prev {
  right: -4%;
}
.swiper-slide-next {
  left: -4%;
}
.swiper-slide-active img,
.swiper-slide-prev img,
.swiper-slide-next img {
  @apply tw-rounded-lg;
}

.swiper-pagination {
  opacity: 0.8;
}

.loading {
  @apply tw-w-full tw-pb-[36%];
}
</style>
