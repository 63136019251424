<template>
  <div id="home-html" class="area-map-box" :class="{ desktop: !mobileMode }">
    <img id="map-image" ref="imageElementRef" :src="imageUrl" usemap="#workmap" :class="{ desktop: !mobileMode }" fetchpriority="high" />

    <map name="workmap">
      <area v-for="(linkData, index) in mapDataComputed" :id="`home-html-${index + 1}`" :key="`${blockName}-${index}`" shape="rect" :coords="linkData.coords" :href="linkData.link" />
    </map>
  </div>
</template>

<script setup>
import { toRefs, computed, ref, onMounted, onUnmounted, inject } from 'vue';
import { useElementSize } from '@vueuse/core';
import { debounce } from 'lodash-es/function';
import { delay } from '@/helper/promise';
import { isMobile, isTablet } from '@/helper/ua.js';

const isPrd = import.meta.env.VITE_TYPE === 'prd';
const isMobileRef = isMobile();
const isTabletRef = isTablet();
const mobileMode = isMobileRef || isTabletRef;

const props = defineProps({
  blockName: { type: String, default: '' },
  imageUrl: { type: String, default: '' },
  mapData: { type: Array, required: true },
  bannerWidthBase: { type: Number, required: true },
});

const { blockName, imageUrl, bannerWidthBase, mapData } = toRefs(props);

const $screen = inject('$screen');

const responsiveRate = ref(1);
const imageElementRef = ref(null);
const { width } = useElementSize(imageElementRef);
const mapDataComputed = computed(() => mapData.value.map((item) => ({ ...item, coords: item.coords.map((value) => Math.round(value * responsiveRate.value)) })));
const setResponsiveRate = debounce(() => {
  const currentWidth = !mobileMode ? 1280 : ( width.value ? width.value : $screen.width );
  responsiveRate.value = currentWidth / bannerWidthBase.value;
});

/** @const {function} areaMapDebug area map 加上外框用於 debug */
const areaMapDebug = () => {
  // 清除現有外框元件 (避免 resize 時重複塞入外框元件)
  document.querySelectorAll('.debug-map-box').forEach((e) => e.remove());
  document.querySelectorAll('area').forEach((area) => {
    const coords = area.coords.split(',').map(Number);
    const { shape } = area;
    const img = document.getElementById('map-image');
    const parentDiv = img.parentElement;
    const div = document.createElement('div');
    // 外框元件加上 style (寬高座標)
    if (shape === 'rect') {
      const [x1, y1, x2, y2] = coords;
      const width = x2 - x1;
      const height = y2 - y1;
      div.style.left = `${x1}px`;
      div.style.top = `${y1}px`;
      div.style.width = `${width}px`;
      div.style.height = `${height}px`;
    }
    // 外框元件加上 class
    div.classList.add('debug-map-box', 'tw-absolute', 'tw-border-2', 'tw-border', 'tw-border-solid', 'tw-border-red-500', 'tw-pointer-events-none');
    // 外框元件塞入父層元件中
    parentDiv.appendChild(div);
  });
  return true;
};

onMounted(async () => {
  await delay(() => {}, 100);
  await setResponsiveRate();
  window.addEventListener('resize', setResponsiveRate);
});

onUnmounted(() => {
  window.removeEventListener('resize', setResponsiveRate);
});

defineOptions({
  name: 'ImageMap',
});
</script>

<style scoped>
.area-map-box {
  @apply tw-w-full tw-p-0 tw-relative;
  &.desktop {
    @apply tw-w-[1280px] tw-h-[640px] tw-p-0;
  }
}

#map-image {
  @apply tw-w-full tw-p-0 tw-block;

  &.desktop {
    @apply tw-w-[1280px] tw-h-[640px] tw-p-0;
  }
}
</style>
