<template>
  <div v-if="allowEmptyLink && isEmptyLink" data-type="route-empty">
    <slot></slot>
  </div>
  <router-link v-else-if="!hasDomainName && !isSubframe && !needRefresh && !useRouteLink" :to="formattedLinkUrl" :title="title" :target="formattedTarget" data-type="route-link">
    <slot></slot>
  </router-link>
  <a-link-usage v-else :href="formattedLinkUrl" :title="title" :target="formattedTarget" data-type="a-link">
    <slot></slot>
  </a-link-usage>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { toRefs, computed } from 'vue';
import ALinkUsage from '@/components/utility/a-link-usage.vue';
import { linkSupportVirtualShelf, linkTargetSupportVirtualShelfAndSubframe } from '@/helper/virtual-shelf';
import { isProtectedPage } from '@/helper/protected-page';
import virtualShelfComposable from '@/composables/utility/virtual-shelf';

const props = defineProps({
  link: { type: String, default: '' },
  title: { type: String, default: '' },
  target: { type: String, default: '_self' },
  allowEmptyLink: { type: Boolean, default: false },
  needCheckProtectedPage: { type: Boolean, default: false },
});

const route = useRoute();
const { link, title, target, allowEmptyLink, needCheckProtectedPage } = toRefs(props);
const { isSubframe, isUseVirtualShelfLink } = virtualShelfComposable();

const hasDomainName = computed(() => /^(http|https)/.test(link.value));
const formattedTarget = computed(() => linkTargetSupportVirtualShelfAndSubframe({ target: target.value, isUseVirtualShelfLink: isUseVirtualShelfLink.value, isSubframe: isSubframe.value }));
// 不能靠 middleware 加 query string layout=virtual-shelf，因為可能是 sub frame，會在 target=_parent 開啟
const formattedLinkUrl = computed(() => {
  if (!isUseVirtualShelfLink.value) return link.value;
  return linkSupportVirtualShelf(link.value);
});
const isEmptyLink = computed(() => !link.value || link.value === '#');
const needRefresh = computed(() => {
  if (isUseVirtualShelfLink.value) return false;
  if (!needCheckProtectedPage.value) return false;
  // 限制頁與非限制頁切換，refresh頁面，限制第三方script載入
  return isProtectedPage(route.path) !== isProtectedPage(link.value);
});

/** @const {computed} useRouteLink 判斷特定頁之間轉跳時只能使用 a link */
const useRouteLink = computed(() => {
  return isProtectedPage(route.path) !== isProtectedPage(link.value);
});
</script>

<script>
export default { name: 'RouterLinkUsage' };
</script>
