import { isEmptyValue } from '@/helper/data-process';

export function formatImagePath(imgPath, baseURL = import.meta.env.VITE_SOURCE_PIC) {
  if (isEmptyValue(imgPath)) return '';
  if (/^http/.test(imgPath)) return imgPath;
  const INDEX_AD_PATH = import.meta.env.VITE_INDEX_AD_PATH;
  // 若是 index_ad, index_slot 開頭則 baseURL 要替換
  const isIndexAd = imgPath.substr(0, 8) === 'index_ad';
  const isIndexSlot = imgPath.substr(0, 10) === 'index_slot';
  const path = isIndexAd || isIndexSlot ? INDEX_AD_PATH : baseURL;
  // 若開頭非 "http" 但也不是 "/" 時補上 "/"
  const mark = !isIndexAd && !isIndexSlot && imgPath.substr(0, 1) === '/' ? '' : '/';

  // fake image
  if (imgPath.includes('fake-images')) return imgPath;

  return `${path}${mark}${imgPath}`;
}
