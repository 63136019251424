import VueGtm from '@gtm-support/vue-gtm';
import { observeTitleForPageview } from '@/plugins/tracking/part/gtm-pageview';
import { isBot } from '@/helper/ua';

export default ({ pluginContext, router, app }) => {
  console.log(`[isbot] gtm ${isBot()}`);
  if (isBot()) {
    const dummyGtm = {
      trackEvent: () => console.log('[GTM] Tracking disabled for bots'),
      trackView: () => console.log('[GTM] Tracking disabled for bots'),
    };

    pluginContext.$gtm = dummyGtm;
    app.provide('$gtm', dummyGtm);
    return false;
  }
  app.use(VueGtm, {
    id: import.meta.env.VITE_GTM_CODE,
    defer: false,
    compatibility: true,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
  pluginContext.$gtm = app.config.globalProperties.$gtm;
  app.provide('$gtm', app.config.globalProperties.$gtm);
  observeTitleForPageview(app.config.globalProperties.$gtm);
  return true;
};
