<template>
  <div class="menu" :class="{ 'tw-invisible': menu.length <= 0 }">
    <div v-if="!rwdMode" class="title">全站分類</div>
    <div class="list" :class="{ 'rwd-mode': rwdMode }">
      <div v-for="(row, rowIndex) in menu" :key="`index-menu-${rowIndex}`" class="tw-row" :class="{ active: rowIndex === 999 }">
        <div :id="`menu-l1-${rowIndex + 1}`" class="L1-left">
          <template v-for="(item, index) in row.level1s" :key="`index-menu-${rowIndex}-${item.id}`">
            <RouterLinkUsage :id="`menu-l1-${rowIndex + 1}-${index + 1}`" :link="getNavLinkPath(item)">{{ item.title }}</RouterLinkUsage>
            <span v-if="row.level1s.length > index + 1">・</span>
          </template>
          <span v-if="rowIndex === 0" class="new-tag">New</span>
        </div>
        <div class="L1-right" :row-index="rowIndex" data-test-id="menu-right">
          <div v-for="(l2item, l2index) in row.level2s" :key="`l2-${l2index}`" class="tw-row">
            <RouterLinkUsage :id="`menu-l2-${rowIndex + 1}-${l2index + 1}`" :link="getNavLinkPath(l2item)" :title="l2item.title" class="L2">{{ l2item.title }}</RouterLinkUsage>
            <div class="L3">
              <template v-for="(l3item, l3index) in l2item.level3s" :key="`l3-${l3index}`">
                <RouterLinkUsage :id="`menu-l3-${rowIndex + 1}-${l2index + 1}-${l3index + 1}`" :link="getNavLinkPath(l3item)" :title="l3item.title" class="L3-list">{{ l3item.title }}</RouterLinkUsage>
                <span v-if="l2item.level3s.length > l3index + 1">∣</span>
              </template>
            </div>
          </div>

          <div v-if="!$isEmpty(row.brands) || !$isEmpty(row.banners)" class="line"></div>

          <div v-if="!$isEmpty(row.brands)" class="tw-row">
            <div class="L2 tw-pt-6">{{ l2Title }}</div>
            <div class="L3 L3-brand">
              <template v-for="(l3item, l3index) in row.brands" :key="`l3-brand-${l3index}`">
                <RouterLinkUsage :id="`menu-brand-${rowIndex + 1}-${l3index + 1}`" :link="getNavLinkPath(l3item)" :title="l3item.title" class="L3-list brand-link"
                  ><img :src="l3item.image" :alt="l3item.title" class="brand-img"
                /></RouterLinkUsage>
              </template>
            </div>
          </div>

          <div v-if="!$isEmpty(row.banners)" class="tw-row">
            <div class="L2 tw-pt-6">{{ $isEmpty(row.brands) ? l2Title : '' }}</div>
            <div class="L3 L3-banner" :class="{ 'tw-pt-3': $isEmpty(row.brands) }">
              <template v-for="(l3item, l3index) in row.banners" :key="`l3-banner-${l3index}`">
                <RouterLinkUsage :id="`menu-sb-${rowIndex + 1}-${l3index + 1}`" :link="getNavLinkPath(l3item)" :title="l3item.title" class="L3-list banner-link"
                  ><img :src="l3item.image" :alt="l3item.title" class="banner-img"
                /></RouterLinkUsage>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { getNavLinkPath } from '@/helper/ad/ad-helper.js';

const props = defineProps({
  rwdMode: { type: Boolean, default: false },
});

const { rwdMode } = toRefs(props);

const homeAdStore = useHomeAd2024Store();
const { getMenu } = storeToRefs(homeAdStore);
const menu = computed(() => getMenu.value?.items || []);

const l2Title = ref('推薦品牌');
</script>

<style scoped>
.title {
  @apply tw-h-[52px] tw-p-4 tw-text-[16px] tw-leading-5 tw-font-medium tw-rounded-t-lg;
  background-color: theme('colors.gold-500');
  color: theme('colors.white');
}

.list {
  @apply tw-w-full tw-h-[476px] tw-flex tw-flex-col tw-justify-evenly tw-relative tw-rounded-none;
  color: theme('colors.black');
  border: 1px solid theme('colors.gold-500');
  background-color: theme('colors.white');

  .tw-row {
    @apply tw-w-full tw-p-0 tw-m-0;
  }
  .tw-row:first-child .L1-left {
    @apply tw-mt-0 tw-text-clip;
  }
  > .tw-row {
    height: inherit;
    @apply tw-inline-flex tw-items-center tw-flex-nowrap tw-justify-between;

    &:hover,
    &.active {
      background-color: theme('colors.gold-50');
      .L1-left a,
      .L1-left span {
        color: theme('colors.gold-500');
      }
      .L1-left span.new-tag {
        color: theme('colors.orange-500');
      }
      .L1-right {
        @apply tw-flex tw-z-[5];
      }
    }
  }
}

.rwd-mode {
  &.list {
    border: none;
  }

  .L1-right {
    top: -1.5rem;
    width: calc(960px - 212px);
    max-width: calc(960px - 212px);

    @media (min-width: 1280px) {
      width: calc(1272px - 212px);
      max-width: calc(1272px - 212px);
    }
  }
  .L3 {
    flex-wrap: wrap;
    padding: 0 8px;
  }

  .L3-brand {
    display: grid;
    grid-template-columns: repeat(7, 65px);
    grid-gap: 16px 8px;
    padding: 16px 16px 0;

    @media (min-width: 1280px) {
      grid-template-columns: repeat(12, 65px);
      gap: 0.5rem;
      padding: 0.5rem;
    }
  }

  .L3-banner {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: hidden;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    width: auto;

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  .banner-img {
    width: 200px;
    height: 100px;
  }
}

.L1-left {
  @apply tw-w-full tw-h-7 tw-px-4 tw-py-1 tw-mx-auto tw-my-[1px] tw-text-xs tw-text-left;
  @apply tw-overflow-hidden tw-text-ellipsis tw-whitespace-pre tw-no-underline;

  &:first-child {
    @apply tw-mt-0;
  }
  &:last-child {
    @apply tw-mb-0;
  }

  a {
    @apply tw-text-sm tw-font-medium;
    color: theme('colors.gray-800');
  }

  span {
    @apply tw-cursor-default;
    color: theme('colors.gray-800');
  }

  span.new-tag {
    @apply tw-h-5 tw-ml-1 tw-px-1 tw-py-[2px] tw-rounded tw-text-xs tw-font-medium tw-inline-block;
    color: theme('colors.orange-500');
    background-color: theme('colors.orange-50');
  }
}

.L1-right {
  @apply tw-w-[1068px] tw-max-w-[1068px] tw-min-h-[476px] tw-m-0 tw-p-0 tw-flex-auto tw-mr-3 tw-rounded-r-lg;
  @apply tw-absolute tw-top-0 tw-left-[211px] tw-flex-col tw-justify-start tw-hidden;
  background: linear-gradient(90deg, theme('colors.gray-50') 160px, theme('colors.white') 0);
  border: 2px solid theme('colors.gray-200');

  .tw-row {
    @apply tw-flex tw-whitespace-pre;
  }
}

.line {
  border-top: 1px solid #f0ebe1;
}

.L2 {
  @apply tw-w-[160px] tw-max-w-[160px] tw-text-right tw-text-sm tw-py-2 tw-px-4;
  @apply tw-overflow-hidden tw-text-ellipsis tw-whitespace-pre tw-no-underline;
  color: theme('colors.gold-500');
  background-color: theme('colors.gray-50');
}

a.L2:hover {
  @apply tw-underline;
}

.L3 {
  width: calc(100% - 160px);
  @apply tw-max-w-[904px] tw-px-4 tw-flex tw-flex-wrap tw-items-center;

  a {
    color: theme('colors.camel-300');
    @apply tw-h-9 tw-py-2 tw-text-sm;
  }

  span {
    color: theme('colors.gray-200');
    @apply tw-text-sm;
  }
}

.L3-brand {
  @apply tw-grid tw-p-4 tw-gap-2;
  grid-template-columns: repeat(12, 65px);
  a {
    @apply tw-h-auto tw-p-0;
  }
  .brand-img {
    @apply tw-w-[65px] tw-h-[65px];
  }
}

.L3-banner {
  @apply tw-w-[662px] tw-grid tw-grid-cols-3 tw-gap-4 tw-pb-4 tw-items-center;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  a {
    @apply tw-h-auto tw-block tw-p-0;
  }
}

.brand-img {
  @apply tw-w-10 tw-h-10 tw-rounded-lg;
}

.banner-img {
  @apply tw-w-[200px] tw-max-h-[100px] tw-rounded-lg;
}
</style>
