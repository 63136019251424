<template>
  <div class="header-fixed-wrapper header tw-w-full" :class="[isForPages ? 'tw-container' : 'index-container', { 'tw-mx-auto': !isForPages }, { 'tw-p-0': isForPages }]" data-test-id="header">
    <!-- header:top banner -->
    <div v-if="haveTopBanner && !scrollMode" class="top-banner tw-w-full tw-p-0">
      <RouterLinkUsage id="header-top" :link="topBannrImageSource.link" :title="topBannrImageSource.alt" target="_blank" data-test-id="top-banner"
        ><img :src="topBannrImageSource.image" :alt="topBannrImageSource.alt" class="tw-rounded-lg"
      /></RouterLinkUsage>
    </div>

    <div id="header-hidden-anchor"></div>

    <!-- header:top link -->
    <div class="top-link links tw-w-full tw-p-0 tw-flex tw-justify-between">
      <ul class="left tw-flex">
        <li><RouterLinkUsage link="/" title="回首頁">回首頁</RouterLinkUsage></li>
        <li><a :href="appRedirectLink()" title="APP下載" target="_blank">APP下載</a></li>
      </ul>
      <HeaderLinks class="right" />
    </div>

    <!-- header: top-eslite-header -->
    <div id="top-eslite-header" ref="headerFixedWrapperRef" class="tw-w-full tw-mx-auto" :class="{ sticky: scrollMode }" data-test-id="top-eslite-header">
      <div class="fixed-box tw-w-full tw-mx-auto" :class="{ 'for-pages': isForPages }">
        <!-- header: logo -->
        <div class="left">
          <RouterLinkUsage id="logo" :link="getLogo.link || '/'" :title="getLogo.alt || '誠品線上'" data-test-id="header-logo">
            <img v-if="!$isEmpty(getLogo.image)" class="logo tw-w-full" :src="getLogo.image" />
            <div v-else class="logo tw-w-full">
              <div class="loading logo-loading mode-122x32 tw-rounded-lg"></div>
            </div>
          </RouterLinkUsage>
        </div>

        <!-- header: search bar & keywordList -->
        <div class="center">
          <HeaderSearchBar />
          <KeywordsComponent :sticky="scrollMode" />
        </div>
        <!-- header: small banner -->
        <div class="right links">
          <RouterLinkUsage
            v-if="smallBannrImageSource.image"
            id="header-sb"
            class="header-small-banner"
            :link="smallBannrImageSource.link"
            :title="smallBannrImageSource.alt"
            data-test-id="small-banner"
            ><img :src="smallBannrImageSource.image"
          /></RouterLinkUsage>
          <div v-else class="loading logo-loading mode-122x32"></div>
          <HeaderLinks class="header-fixed-mode" />
        </div>
      </div>
    </div>

    <div class="nbsp"></div>
    <div class="tw-w-full tw-p-0">
      <div class="fraud-prevention">
        <a href="https://events.eslite.com/temp/211228-Fraud/edm.html"><img src="@/static/images/Microphone.svg" />防詐3要訣：不聽信、不操作、掛斷電話 <span>(詳)</span></a>
      </div>
    </div>

    <div class="tw-h-4"></div>

    <full-banner />
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onUnmounted, onBeforeMount, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartItemsStore } from '@/stores/cart-items';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';

import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import HeaderLinks from '@/components/layout/elements/header-links.vue';
import KeywordsComponent from '@/components/layout/elements/keywords-component.vue';
import HeaderSearchBar from '@/components/layout/elements/header-search-bar.vue';
import FullBanner from '@/components/layout/full-banner.vue';

import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';

const props = defineProps({
  scrollMode: { type: Boolean, default: false },
  isForPages: { type: Boolean, default: false },
});
const { scrollMode, isForPages } = toRefs(props);

const isLoaded = ref(false);
const headerFixedWrapperRef = ref(null);

// store
const homeAd2024Store = useHomeAd2024Store();
const { fetchHomeAd: fetchHomeAd2024 } = homeAd2024Store;
const { getLogo, getTopBanner, getHeaderSmallBanner } = storeToRefs(homeAd2024Store);
const topBannrImageSource = computed(() => getTopBanner.value);
const smallBannrImageSource = computed(() => getHeaderSmallBanner.value);
const haveTopBanner = computed(() => !!topBannrImageSource.value?.image);

const cartItemsStore = useCartItemsStore();
const { initCartQuantity, clearQuantity } = cartItemsStore;

const appRedirectLink = () => {
  const ua = navigator.userAgent;
  if (ua.match(/(iPad|iPhone|iPod)/i)) return 'https://apps.apple.com/tw/app/id1527231018';
  if (ua.match(/Android/i)) return 'https://play.google.com/store/apps/details?id=com.eslite.tw';
  return 'https://vip.eslite.com/esliteapp/tw/';
};

onBeforeMount(() => {
  isLoaded.value = true;
});
onMounted(async () => {
  fetchHomeAd2024([homePageADTypeEnum.logo]);
  initCartQuantity();
});
onUnmounted(clearQuantity);
</script>

<script>
export default { name: 'HeaderWith2024Index' };
</script>

<style scoped>
@import url('@/assets/tailwind-2024index.css');

.header-fixed-wrapper {
  /** 畫面 */
  .top-banner {
    @apply tw-mb-5;
    a,
    img {
      @apply tw-w-full tw-max-w-full tw-max-h-[90px] tw-h-auto tw-block;
    }
  }

  li {
    @apply tw-leading-4 tw-my-1 tw-mx-0 tw-font-medium tw-text-center tw-no-underline tw-text-sm tw-whitespace-pre;
    border-left: 1px solid theme('colors.gray-c');
    color: theme('colors.gray-500');
  }

  /** header top-link 結構 */
  .top-link {
    @apply tw-mb-5;

    .left {
      li {
        @apply tw-px-3;
      }
      li:first-child {
        @apply tw-pl-0;
        border-left: none;
      }
      li:last-child {
        @apply tw-pr-0;
      }
    }
  }

  .links {
    @apply tw-flex tw-justify-between;

    ul {
      @apply tw-pb-0 tw-mb-0;
    }

    /** header link: default (灰色) */
    li a {
      color: theme('colors.gray-6');
      @apply tw-text-sm tw-font-medium;
    }
    /** header link: 購物車數量 (紅字) */
    span.red {
      color: theme('colors.pink');
    }
    /** header link: 名字 (金字) */
    li.gold {
      color: theme('colors.gold-500');
      @apply tw-text-sm tw-font-medium tw-pr-0;

      & + li {
        border-left: none;
      }
    }
  }
}

#top-eslite-header {
  background: theme('colors.white');
  /* @apply tw-w-full tw-p-0 tw-flex tw-gap-10 tw-justify-between; */

  > div,
  > div > div {
    @apply tw-border-0;
  }

  .fixed-box {
    @apply tw-w-[1280px] tw-p-0 tw-flex tw-gap-10 tw-justify-between;
    background: theme('colors.white');

    &.for-pages {
      @apply tw-w-auto tw-gap-8;
      > .left {
        @apply [@media(min-width:1280px)]:tw-max-w-[250px] tw-max-w-[165px];
        .logo {
          @apply [@media(min-width:1280px)]:tw-w-[250px] tw-w-[165px] tw-h-fit;
        }
      }
      > .center {
        @apply [@media(min-width:1280px)]:tw-max-w-[710px] [@media(min-width:1280px)]:tw-w-[710px] tw-max-w-[560px];
      }
      > .right {
        @apply [@media(min-width:1280px)]:tw-max-w-[250px] tw-max-w-[187px];
      }
    }

    /** logo(left) : search(center) : banner(right) */
    > .left {
      @apply tw-w-[250px] tw-h-[66px] tw-flex tw-text-left tw-flex-auto;

      a {
        @apply tw-flex tw-text-center;
      }
      .logo {
        @apply tw-w-[250px] tw-max-h-[66px] tw-mt-0;
      }
    }
    > .center {
      @apply tw-w-[700px] tw-flex-auto;
    }
    > .right {
      @apply tw-w-[250px] tw-flex-auto tw-text-right;
      img,
      .logo-loading {
        @apply tw-w-full tw-max-h-[80px] tw-rounded-lg;
      }
    }
  }

  /**
   * sticky-header
   */
  &.sticky {
    @apply tw-w-full tw-max-w-full tw-h-[108px] tw-px-0 tw-pt-8 tw-pb-[34px] tw-flex tw-gap-4;
    @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-100;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);

    .header-fixed-mode {
      @apply tw-flex;
    }

    .fixed-box {
      > div {
        @apply tw-text-sm tw-font-medium tw-grid tw-items-center;
      }

      > .left {
        @apply tw-w-[163px] tw-h-[42px];

        .logo {
          @apply tw-w-[163px] tw-h-[42px];
        }
      }

      > .center {
        @apply tw-w-[406px];
        .keywords {
          @apply tw-hidden;
        }
      }

      > .right {
        @apply tw-w-[662px] tw-h-[36px] tw-justify-end;
        .loading {
          @apply tw-hidden;
        }
        .header-small-banner {
          @apply tw-hidden;

          img {
            @apply tw-w-full tw-max-h-full tw-rounded-lg;
          }
        }
        li {
          @apply tw-my-1 tw-px-1;
        }
      }
    }
  }
}

.header-fixed-mode {
  @apply tw-hidden;
}

.fraud-prevention {
  @apply tw-h-8 tw-px-2 tw-text-13px tw-leading-[18px] tw-font-normal tw-inline-flex tw-rounded-lg;
  background-color: theme('colors.camel-50');
  a {
    @apply tw-flex tw-items-center;
    color: theme('colors.gold-500');
    text-decoration: none;
  }
  span {
    @apply tw-ml-1;
    color: theme('colors.gold-300');
  }
}
</style>
