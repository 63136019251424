import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useProductPageStore = defineStore('productPage', () => {
  const isPageRendered = ref(false);
  const isPageRenderedGetter = computed(() => isPageRendered.value);
  const setPageRendered = (val) => {
    isPageRendered.value = val;
  };

  return {
    isPageRenderedGetter,
    setPageRendered,
  };
});
