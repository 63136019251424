import { map, pipe, trim, join } from 'ramda';
import { emptyReplace, rejectEmpty } from '@/helper/data-process';
import { yesNoEnum } from '@/constant/common/enums';
import { productStatusIsCollect } from '@/constant/product/product-enable-status';
import { formatImagePath } from '@/helper/image-formatter';

export const trimRow = map((x) => trim(x));
export const filterArray = pipe(trimRow, (x) => rejectEmpty(x));
// 承上，以「逗號」串成字串
export const toString = pipe(trimRow, (x) => rejectEmpty(x), join(','));

export function formatArrayField({ data, fieldName }) {
  const fieldSourceName = `${fieldName}Source`;
  const formatedData = emptyReplace(data, []);
  return {
    [fieldName]: toString(formatedData),
    [fieldSourceName]: filterArray(formatedData),
  };
}

export const isTrueValue = (x) => x === yesNoEnum.yes || x === true;

export function formatSearchProduct(product) {
  const productId = emptyReplace(product.id, '');
  const isBook = isTrueValue(product.is_book);
  const status = emptyReplace(product.status, '');
  const { author, authorSource } = formatArrayField({ data: product.author, fieldName: 'author' });
  const { publisher, publisherSource } = formatArrayField({ data: product.manufacturer, fieldName: 'publisher' });

  return {
    // necessary
    id: productId,
    guid: productId,
    url: `/product/${productId}`,
    name: emptyReplace(product.name, ''),
    author,
    authorSource,
    brandName: product.brandName,
    compiler: product.compiler,
    painter: product.painter,
    translator: product.translator,
    image: formatImagePath(product.product_photo_url),
    // 長條版保留欄位
    publisher,
    publisherSource,
    publishDate: emptyReplace(product.manufacturer_date, ''),
    description: emptyReplace(product.description, ''),
    activities: [],
    status,
    isStatusCollectable: productStatusIsCollect[status] || false,
    isAdult: isTrueValue(product.restricted),
    limit: emptyReplace(product.order_qty_limit, null),
    accountLimit: emptyReplace(product.account_qty_limit, null),
    // 不即時抓收藏，預設都 false
    isCollect: false,
    // 商品類型：書 or 非書
    isBook,
    // 試閱試聽
    hasTrialButton: isBook && product.has_trial === yesNoEnum.yes,
    // bridgewell tracking code
    categories: emptyReplace(product.categories, []),
    stock: parseInt(product.stock, 10) || 0,
    isEbook: product?.is_ebook || false,
  };
}
