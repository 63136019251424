import { last, split, pipe, forEach, length, propEq, find } from 'ramda';
import axios from 'axios';
import { checkColumnsKeys, isProduction } from '@/helper/validate-helper';

const devErrorPrint = (error) => {
  // prd 不顯示
  if (import.meta.env.VITE_TYPE === 'prd') return;
  if (error?.isAxiosError === true || error.name === 'AxiosError') {
    console.group(' === [errorHandler / xhr Error] ===');
    console.log({
      name: error.name || '',
      code: error.code || '',
      message: error.message || '',
      response: error.response || {},
      request: error.request || {},
      config: error.config || {},
    });
  } else {
    console.group(' === [errorHandler / Schema Check Error] ===');
    console.log({ ...error });
  }
  console.groupEnd();
};

const errorHandler = (error) => {
  devErrorPrint(error);
  // schema error 格式  = {error, response}
  // network error 保留原本的格式，後面的錯誤處理可用 nuxt.content
  if (error?.isAxiosError === true) throw error;
  // keep data response : 用 return => 不讓 schema check 的錯誤中斷流程
  return { isAxiosError: error.isAxiosError, ...error.response };
};

const checkSchema = (schema, options) => async (response) => {
  try {
    // 檢查是否有多出欄位
    checkColumnsKeys(schema, response.data, options.url);
    // check api response format
    await schema.validateSync(response.data);
    return response;
  } catch (error) {
    // todo: 為了 code 不炸裂，之後有 log 紀錄(ex.bugsnag ...)可以改用紀錄。
    // eslint reason: 如果 Promise 使用了非 Error 的值作為拒絕原因，那麼就很難確定 reject 在哪裡產生。
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ error, response, isAxiosError: false });
  }
};

const grepParamsWithSlideUrl = pipe(split('/'), last, split(','));

/**
 * checkMultiSchema
 * @param schemas
 * @param options
 * @returns {function(*): Promise<*>}
 */
const checkMultiSchema = (schemas, options, keyName) => async (response) => {
  // 拆解取出餐數（ex.版位）
  const params = options?.data || options?.params || grepParamsWithSlideUrl(options.url);
  const errorSummary = [];

  await forEach(async (parameter) => {
    try {
      const schema = find(propEq('key', parameter))(schemas);
      // 有 fetch 這個版位，但是 schema 缺少
      if (!schema && !isProduction()) throw new Error(`[multi schema check]缺少 key 為 ${parameter} 的 schema。`);
      // multi 欄位辨認用的 key，舉例： 首頁 banner_type
      const data = find(propEq(keyName, parameter))(response.data);
      // 有送出請求，但後端整個資料（版位）缺少
      if (!data && !isProduction()) throw new Error(`[multi schema check]缺少 response 為 ${parameter} 的 相關資料。`);
      await schema.schema.validateSync(data);
    } catch (error) {
      errorSummary.push(error);
    }
  })(params);
  // wait for validate result
  await errorSummary;
  // 有 error
  // eslint-disable-next-line prefer-promise-reject-errors
  if (length(errorSummary) > 0) return Promise.reject({ errorSummary, response, isAxiosError: false });
  return response;
};

export default class XhrService {
  constructor() {
    this.xhrInstance = axios;
  }

  fetch(options, schema = null) {
    // todo: 測試用 dto 會用到，暫(｡･∀･)ﾉﾞ不刪除
    if (!isProduction()) console.log('fetch service execute', options.url);
    // 沒有傳入 schema 則不做 response 驗證
    if (!schema) return this.xhrInstance(options);

    // 執行 xhr 後，驗證 response schema
    return this.xhrInstance(options).then(checkSchema(schema, options)).catch(errorHandler);
  }

  fetchWithMultiSchema(options, schemas, keyName) {
    return this.xhrInstance(options).then(checkMultiSchema(schemas, options, keyName)).catch(errorHandler);
  }
}
