export const delPreloadImage = () => {
  // 找到與指定ID相同的link元素
  const link = document.getElementById('imagePreload');
  // 如果link元素存在，則從文檔樹中刪除它
  if (link) {
    link.parentNode.removeChild(link);
  }
};
/**
 * PRELOAD LARGEST CONTENTFUL PAINT IMAGE
 * @param src
 * @returns {{as: string, rel: string, href, type: string}}
 */
export const preloadImage = (src) => {
  if (document.getElementById('imagePreload')) delPreloadImage();
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = src;
  link.setAttribute('fetchpriority', 'high');
  link.id = 'imagePreload';
  document.head.appendChild(link);
};
