import { computed } from 'vue';
import { useHead } from '@unhead/vue';
import { titleAndAlternate, LDDefaultHome, OGMetaData } from '@/constant/seo';

export default function () {
  // 首頁
  console.group(`[head] set home head`);
  console.log(LDDefaultHome);
  console.groupEnd();
  useHead({
    title: titleAndAlternate,
    script: [computed(() => ({ type: 'application/ld+json', children: JSON.stringify(LDDefaultHome) }))],
    meta: OGMetaData,
  });
}
