<template>
  <div v-if="needPreload" :class="imgClass">
    <img v-default-image="defaultImage" :src="pureImageSource" :class="imgClass" :name="imgName" :alt="alt" />
  </div>
  <div v-else v-lazy-container="{ selector: 'img' }" :class="imgClass">
    <img v-default-image="defaultImage" :data-src="pureImageSource" :data-error="error" :data-loading="loading" :class="imgClass" :name="imgName" :alt="alt" />
  </div>
</template>

<script setup>
import { ref, toRefs, computed, inject } from 'vue';
import WebpPathConvertService, { isOriginSourceWebp } from '@/services/webp-path-convert-service';
import { productDefaultImg, productFail, loadingLargeImg } from '@/constant/images-path';

const props = defineProps({
  src: { type: String, default: '' },
  alt: { type: String, default: '' },
  isCircle: { type: Boolean, default: false },
  isCover: { type: Boolean, default: false },
  error: { type: String, default: productFail },
  loading: { type: String, default: loadingLargeImg },
  needPreload: { type: Boolean, default: false },
});

const { src, alt, isCircle, isCover, error, loading } = toRefs(props);
const $isSupportWebp = inject('$isSupportWebp');
const defaultImage = ref(productDefaultImg);

// 未加入圖片存在檢查的圖片位置
const pureImageSource = computed(() => {
  if (isOriginSourceWebp) return src.value;
  return $isSupportWebp ? WebpPathConvertService.convertPath(src.value) : src.value;
});
const imgClass = computed(() => ({ circle: isCircle.value, cover: isCover.value }));
const imgName = computed(() => (isOriginSourceWebp ? '' : `${alt.value}-webp-lazy`));
</script>

<script>
export default { name: 'LazyWebpImage' };
</script>

<style scoped>
div,
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.circle {
  border-radius: 50%;
}
.cover {
  object-fit: cover;
}
</style>
