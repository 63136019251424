export const title = `誠品線上`;
export const alternateName = `閱讀與生活的無盡想像`;
export const titleAndAlternate = `${title} - ${alternateName}`;
export const domain = `https://www.eslite.com`;
export const eventDomain = `https://events.eslite.com`;
export const abstract = '誠品以「人文、藝術、創意、生活」為核心價值，由推廣閱讀出發，並透過線上網路，傳遞博雅的溫度，打造全新的文化場域。';
export const logoUrl = `${eventDomain}/logo/logo.png`;
export const ogLogoUrl = `${domain}/eslite-og-logo.png`;
export const fbAppID = '416171199434689';
export const socialUrls = ['https://www.facebook.com/eslite/', 'https://www.facebook.com/eslite.spectrum/', 'https://www.instagram.com/eslite_global/', 'https://twitter.com/eslite_japan/'];

/** @const {object} LDWebSite home */
export const LDWebSite = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  '@id': domain,
  name: title,
  alternateName,
  url: domain,
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: `${domain}/Search?query={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  ],
};

/** @const {object} LDdataOrg Organization */
export const LDdataOrg = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  '@id': domain,
  name: title,
  alternateName,
  abstract,
  url: domain,
  logo: logoUrl,
  sameAs: socialUrls,
  image: logoUrl,
};

/** @const {object[]} LDDefaultHome 預設首頁的 LD+JSON 資訊 */
export const LDDefaultHome = [LDWebSite, LDdataOrg];

/** @const {object[]} OGMetaData og type meta */
export const OGMetaData = [
  { name: 'description', content: abstract, 'data-rh': true },
  { property: 'fb:app_id', content: fbAppID, 'data-rh': true },
  { property: 'og:title', content: `${title} | ${alternateName}`, 'data-rh': true },
  { property: 'og:site_name', content: `${title} | ${alternateName}`, 'data-rh': true },
  { property: 'og:description', content: abstract, 'data-rh': true },
  { property: 'og:url', content: domain, 'data-rh': true },
  { property: 'og:type', content: 'website', 'data-rh': true },
  { property: 'og:image', content: ogLogoUrl, 'data-rh': true },
  { property: 'og:locale', content: 'zh_TW', 'data-rh': true },
];

export const LDBook = {
  '@context': 'http://schema.org',
  '@type': 'Book',
  '@id': 'https://www.eslite.com/product/10012072012682625840001',
  url: 'https://www.eslite.com/product/10012072012682625840001',
  name: 'Following the Moon',
  abstract: 'Following the Moon：《大熊貓與小小龍的相伴旅程》及《大小貓的相遇旅程》全球暢銷作者的新作閱讀這個美麗插圖、充滿感人故事的書籍，講述一隻睿智且年邁的狼帶領一隻迷失的旅程。',
  description:
    "Following the Moon is a heartwarming illustrated book by the globally popular author of 'The Panda and the Tiny Dragon'. Follow the story of a wise old wolf guiding a lost soul on a journey filled with inspiration and life lessons.",
  image: 'https://s.eslite.com/b2b/newItem/2024/09/09/1936_094859443_377_mainCoverImage1.jpg',
  datePublished: '2024-09-19',
  inLanguage: 'zh-Hant',
  author: [
    {
      '@type': 'Person',
      name: '詹姆斯．諾柏瑞',
    },
  ],
  workExample: {
    '@type': 'Book',
    isbn: '9780241686737',
    bookFormat: 'http://schema.org/Paperback',
    potentialAction: {
      '@type': 'ReadAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://www.eslite.com/product/10012072012682625840001',
        actionPlatform: ['http://schema.org/DesktopWebPlatform', 'http://schema.org/MobileWebPlatform'],
      },
    },
  },
  publisher: {
    '@type': 'Organization',
    name: '', // 出版社/發行商
  },
  numberOfPages: '', // 書本頁數
  sku: '', // sku code (book: ISBN)
  offers: {
    '@type': 'Offer',
    url: 'https://www.eslite.com/product/10012072012682625840001',
    priceCurrency: 'TWD',
    price: '450',
    availability: 'https://schema.org/InStock',
    priceValidUntil: '2025-01-01',
    seller: {
      '@type': 'Organization',
      name: '誠品線上',
    },
  },
};

export const LDBreadcrumb = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [],
};
