<template>
  <errorLayout v-if="isErrorLayout" :error="{ status: errorStatus }"></errorLayout>
  <virtual-shelf v-else-if="isVirtualShelfLayout" />
  <component :is="components[layout]" v-else-if="!$isEmpty(layout)" :key="layout"></component>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHead } from '@unhead/vue';

import blankLayout from '@/layouts/blank.vue';
import cartLayout from '@/layouts/cart.vue';
import defaultLayout from '@/layouts/default.vue';
import errorLayout from '@/layouts/error.vue';
import esliteLayout from '@/layouts/eslite.vue';
import exhibitionLayout from '@/layouts/exhibition.vue';
import giftAiLayout from '@/layouts/gift-ai.vue';
import home2024indexLayout from '@/layouts/home-2024index.vue'; // 首頁改版的 home layout
import home2024mobileLayout from '@/layouts/home-2024mobile.vue'; // 首頁改版的 home mobile layout
import homeBaseLayout from '@/layouts/home-base.vue'; // 新版 home
import homeLayout from '@/layouts/home.vue'; // 舊版 home
import homeMemberProfileLayout from '@/layouts/home-member-profile.vue';
import marketLayout from '@/layouts/market.vue';
import testonlyLayout from '@/layouts/test-only.vue';
import VirtualShelf from '@/layouts/virtual-shelf.vue';

import virtualShelfComposables from '@/composables/utility/virtual-shelf';
import { useLayoutErrorStore } from '@/stores/layout-error';
import { layoutErrorStatusEnum } from '@/router/layout';
import { getDefaultMeta } from '@/helper/meta';
import { importDefaultGoogleFonts } from '@/helper/meta/google-fonts';
import { isEmptyValue } from '@/helper/data-process';

const components = {
  blankLayout,
  cartLayout,
  defaultLayout,
  errorLayout,
  esliteLayout,
  exhibitionLayout,
  giftAiLayout,
  home2024indexLayout,
  home2024mobileLayout,
  homeBaseLayout,
  homeLayout,
  homeMemberProfileLayout,
  marketLayout,
  testonlyLayout,
  VirtualShelf,
};

const route = useRoute();

// ?layout=virtual_shelf
const { isVirtualShelfLayout } = virtualShelfComposables();
const layoutErrorStore = useLayoutErrorStore();
const { getErrorStatus } = storeToRefs(layoutErrorStore);

/**
 * Computed
 */
const errorStatus = computed(() => getErrorStatus.value);
const isErrorLayout = computed(() => errorStatus.value !== null);
const layoutKey = computed(() => route.meta.layout || '');
const hasLayout = computed(() => !isEmptyValue(layoutKey.value) && Object.keys(components).includes(`${layoutKey.value}Layout`));
const layout = computed(() => (hasLayout.value ? `${layoutKey.value}Layout` || 'defaultLayout' : ''));

/**
 * Methods
 */
const setErrorStatus = (error) => layoutErrorStore.setErrorStatus(error);
const checkIsUnknownRoute = (route) => {
  if (route.path === '/' || isEmptyValue(route.path)) return false;
  return isEmptyValue(route.name);
};

const metaInfo = function () {
  // 預先載入字體，避免字體閃爍
  // 不需要根據路由判斷，直接在應用啟動時載入會更好
  importDefaultGoogleFonts();

  // 如果是首頁，才需要額外的 meta 資訊
  return route.name === 'index' ? getDefaultMeta() : {};
};
useHead(metaInfo());

watch(
  route,
  (val) => {
    if (checkIsUnknownRoute(val)) {
      setErrorStatus(layoutErrorStatusEnum.error404);
    }
  },
  {
    immediate: true,
  },
);
</script>

<script>
export default { name: 'App' };
</script>
