import { Base64 } from 'js-base64';
import { pipe, join, ifElse, split, reverse, map, trim, filter, sortBy, prop, isEmpty } from 'ramda';
import callSearchApi from '@/api/search';
import { isEmptyValue, rejectEmpty, monthsBeforeCurrent, emptyReplace } from '@/helper/data-process';
import { pipePrint } from '@/helper/debug';
import { searchSortTypeEnum } from '@/constant/search/sort-type';
import { formatImagePath } from '@/helper/image-formatter';
import { productStatusIsCollect } from '@/constant/product/product-enable-status';
import { yesNoEnum } from '@/constant/common/enums';
import { formatArrayField, isTrueValue } from '@/helper/search/search-products-block-formatter';

const formatToDateString = (date) => `${monthsBeforeCurrent(date).toISOString().slice(0, 19)}Z`;
const parseParamsPrice = pipe((x) => [x[0] || 0, x[1] || ''], join(','));

const stringifyQueryArrayValue = (value) => (isEmptyValue(value) ? [] : JSON.stringify(value));
const parseQueryArray = ifElse(
  (value) => isEmptyValue(value), // vitest 先建 const，之後才 import，因此不能直接用 isEmptyValue，需改（x）=> isEmptyValue(x)
  () => [],
  (value) => JSON.parse(Base64.decode(value)),
);

export const searchPageQueryEnum = {
  query: 'query',
  keyword: 'keyword',
  manufacturer: 'manufacturer',
  author: 'author',
  originAuthor: 'originAuthor',
  compiler: 'compiler',
  translator: 'translator',
  painter: 'painter',
  brandName: 'brandName',
  stock: 'stock',
  finalPrice: 'final_price',
  publishDate: 'publishDate',
  sort: 'sort',
  size: 'size',
  display: 'display',
  start: 'start',
  status: 'status',
  categories: 'categories',
  exp: 'exp',
};

/**
 * 傳送給 API 前的參數整理
 * build Parameters flow
 */
const isWeightSort = (sort) => {
  const afterSplit = split(' ', sort);
  return afterSplit[0] === searchSortTypeEnum.score;
};
const formatParameters = (params) => ({
  q: params.keyword,
  manufacturer: stringifyQueryArrayValue(params.publishers),
  author: stringifyQueryArrayValue(params.authors),
  originAuthor: stringifyQueryArrayValue(params.originAuthors),
  compiler: stringifyQueryArrayValue(params.compilers),
  translator: stringifyQueryArrayValue(params.translators),
  painter: stringifyQueryArrayValue(params.painters),
  brandName: stringifyQueryArrayValue(params.brandNames),
  stock: '', // todo: stock = 1, 庫存大於１的商品
  final_price: parseParamsPrice(params.price),
  manufacturer_date: params.publishDate === 0 ? '' : formatToDateString(params.publishDate),
  sort: isWeightSort(params.sort) ? 'weight' : params.sort,
  size: params.pageSize,
  start: params.start,
  status: typeof params.status === 'string' ? params.status : join(',', params.status),
  categories: stringifyQueryArrayValue(params.categories),
});

// 呼叫 api 前的參數整理
const buildParameters = (params) => pipe(formatParameters, rejectEmpty, pipePrint)(params); // vitest 先建 const，之後才 import，因此不能直接用 pipe(...)，需改（x）=> pipe(...)(x)

/**
 *
 * @param queryParams
 * @param route
 * @return {{price: (*|string), start: (number|number), publishDate: (number|number), publishers: ([]|any), categories: ([]|any), sort: (*), keyword: *, authors: (*), status}}
 */

const formatResultProducts = map((input) => {
  // v2 的 search 資料結構 {id: "1231414", fields: {... 商品欄位}
  const fields = emptyReplace(input?.fields, {});
  const product = {
    id: input?.id,
    ...fields,
  };
  // v2 商品欄位處理
  const productId = emptyReplace(product.id, '');
  const isBook = isTrueValue(product.is_book);
  const status = emptyReplace(product.status, '');
  const { author, authorSource } = formatArrayField({ data: product.author, fieldName: 'author' });
  const { publisher, publisherSource } = formatArrayField({ data: product.manufacturer, fieldName: 'publisher' });
  return {
    // necessary
    id: productId,
    guid: productId,
    url: `/product/${productId}`,
    name: emptyReplace(product.name, ''),
    author,
    authorSource,
    brandName: product.brand,
    compiler: product.compiler,
    painter: product.painter,
    translator: product.translator,
    image: formatImagePath(product.product_photo_url),
    // 長條版保留欄位
    publisher,
    publisherSource,
    publishDate: emptyReplace(product.manufacturer_date, ''),
    description: emptyReplace(product.description, ''),
    activities: [],
    status,
    isStatusCollectable: productStatusIsCollect[status] || false,
    isAdult: isTrueValue(product.restricted),
    // limit: emptyReplace(product.order_qty_limit, null),
    // accountLimit: emptyReplace(product.account_qty_limit, null),
    // 不即時抓收藏，預設都 false
    isCollect: false,
    // 商品類型：書 or 非書
    isBook,
    // 試閱試聽
    hasTrialButton: isBook && product.has_trial === yesNoEnum.yes,
    // bridgewell tracking code
    categories: emptyReplace(product.categories, []),
    // ebook
    isEbook: product.is_ebook || false,
    vendorSn: emptyReplace(product.manufacturer_sn, ''),
    mch: product.mch,
  };
});

/**
 * 將資料調整成 checkbox 用的格式
 * @param x
 * @return {{count, label, value}}
 */
const replaceInt = pipe(
  (x) => emptyReplace(x, 0),
  (x) => parseInt(x, 10),
);
const formatForCheckbox = (x) => ({
  label: emptyReplace(x.value, ''),
  value: emptyReplace(x.value, ''),
  count: replaceInt(x.count),
});

/**
 * array 過濾空白
 * 將 [" "] array 濾成 []
 * 將 ["xxx", " "] array 濾成 ["xxx"]
 * @type {f1}
 */
// 將 [{value: " ", count: 1}] 中 value : " " 的物件濾掉
const formatToCheckbox = pipe(
  // trim object.value " "
  map((row) => ({ ...row, value: trim(row.value) })),
  // reject empty object.value
  filter((row) => !isEmpty(row.value)),
  // format for checkbox
  map(formatForCheckbox),
  sortBy(prop('count')),
  reverse,
);

const formatFacetCategories = (categories = []) => {
  return map(
    (category) => ({
      label: '',
      value: parseInt(category.value, 10),
      count: category.count,
    }),
    categories,
  );
};

const formatFacets = (facets = {}) => ({
  categories: formatFacetCategories(emptyReplace(facets.categories?.buckets, [])),
  authors: formatToCheckbox(emptyReplace(facets.author?.buckets, [])),
  originAuthors: formatToCheckbox(emptyReplace(facets.originAuthor?.buckets, [])),
  compilers: formatToCheckbox(emptyReplace(facets.compiler?.buckets, [])),
  translators: formatToCheckbox(emptyReplace(facets.translators?.buckets, [])),
  painters: formatToCheckbox(emptyReplace(facets.painters?.buckets, [])),
  brandNames: formatToCheckbox(emptyReplace(facets.brandNames?.buckets, [])),
  publishers: formatToCheckbox(emptyReplace(facets.manufacturer?.buckets, [])),
});

const search = (params, pageSize, abortController, token = 'false') => {
  return callSearchApi(buildParameters({ ...params, pageSize }), abortController, 2, token).then((res) => {
    return res;
  });
};
export default {
  name: 'SearchService',
  search,
  parseQueryArray,
  formatResultProducts,
  formatFacets,
};

export const searchServiceExportedForTesting = {
  buildParameters,
};
