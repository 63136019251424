import { scrollBehaviorOffsetY } from '@/constant/scroll';
import { delayCallback } from '@/helper/promise';

export const isElementInScreen = (element) => {
  const windowHeight = window.innerHeight;
  // buffer = 1/2 畫面高
  const bufferView = windowHeight / 2;
  // +-30px 補 element 之間間距
  const halfSpaceBetween = 30;
  const topBufferView = bufferView + halfSpaceBetween;
  const bottomBufferView = bufferView - halfSpaceBetween;
  // 取得元素在頁面上的位置
  const position = element.getBoundingClientRect();
  // element 覆蓋全畫面
  if (position.top <= 0 && position.bottom >= windowHeight) return true;
  // element 在畫面內
  if (position.top > 0 && position.bottom < windowHeight) return true;
  // element top 在 0 ~ (1/2 + 30px) 畫面高
  if (position.top > 0 && position.top < topBufferView) return true;
  // element bottom 在 (1/2 - 30px) ~ 1 畫面高
  if (position.bottom > bottomBufferView && position.bottom <= windowHeight) return true;
  return false;
};

export const isElementTopBelowWindowTop = (element) => {
  // 取得元素在頁面上的位置
  const position = element.getBoundingClientRect();
  return position.top > 0;
};

export const isElementInScreenById = (id) => {
  const element = document.querySelector(`#${id}`);
  if (!element) return false;
  return isElementInScreen(element);
};

export const firstElementInScreenByIds = (ids) => {
  const inScreenIds = ids.filter((id) => isElementInScreenById(id));
  return inScreenIds[0];
};

const getTopWithScrollBehaviorOffset = function (top) {
  if (top < scrollBehaviorOffsetY) return top;
  return top - scrollBehaviorOffsetY;
};

export const scrollToHash = function (hash) {
  // 過濾 Facebook Click ID
  if (new RegExp(/#fbclid/gm).test(hash)) return;
  if (!hash) return;
  const element = document.querySelector(hash);
  if (!element) return;
  const top = element.offsetTop;
  window.scrollTo(0, getTopWithScrollBehaviorOffset(top));
};

const isElementTopAboveWindowBottomById = (id) => {
  const element = document.getElementById(id);
  if (!element) return false;
  const position = element.getBoundingClientRect();
  return position.top < window.innerHeight;
};

export const lastElementTopAbloveWindowBottomByIds = (ids) => {
  const reverseIds = [...ids].reverse();
  return reverseIds.find((id) => isElementTopAboveWindowBottomById(id));
};

/**
 * 取得目前座標 { x: 左右, y: 上下 }
 * @returns {{x: Number, y: Number}}
 */
export function getPosition() {
  const { scrollX } = window;
  const scrollY = window.scrollY || document.documentElement.scrollTop;
  return {
    left: scrollX,
    top: scrollY,
  };
}

// 避免頁面內容還沒更新，就先執行scroll
/**
 * 畫面位移
 * @param {{top: number, left: number}} value 位移座標 { x: number, y: number }，x水平 y垂直
 * @param {number} timer 時間 default 0
 * @param behavior
 * @returns {promise}
 */
export function asyncScroll(value = { left: 0, top: 0 }, timer = 300, behavior = 'smooth') {
  return new Promise((resolve) => {
    setTimeout(async () => {
      await window?.scrollTo({
        top: value?.top || 0,
        left: value?.left || 0,
        behavior,
      });
      return resolve();
    }, timer);
  }).then((res) => delayCallback(res, 200));
}

/**
 * scroll 到 Element
 */
export const scrollToElement = function (element) {
  const yOffset = 0;
  const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
  window.scrollTo(0, y);
};
