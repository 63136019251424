<template>
  <div class="tw-w-full tw-p-0 tw-bg-gray-50">
    <div class="index-page" :class="{ sticky: scrollMode }">
      <!-- B007 -->
      <div :ref="(el) => addSectionRef(el, 0)" class="banner-box"><BigSlideComponent title="大看板" /></div>
      <!-- A001 -->
      <NavLinks v-if="!isVirtualShelfLayout" :link-data="eightLinks" />
      <!-- B001 -->
      <div v-if="haveTopBanner" class="banner-box tw-p-0">
        <RouterLinkUsage id="header-top" :link="topBannrImageSource.link" :title="topBannrImageSource.alt" target="_blank" class="tw-block"
          ><img :src="topBannrImageSource.imageMobile" :alt="topBannrImageSource.alt" class="tw-w-full"
        /></RouterLinkUsage>
      </div>
      <!-- 大聲公 -->
      <div v-if="!isVirtualShelfLayout" class="banner-box fraud-prevention-box">
        <div class="fraud-prevention">
          <a href="https://events.eslite.com/temp/211228-Fraud/edm.html"><img src="@/static/images/Microphone.svg" />防詐3要訣：不聽信、不操作、掛斷電話 <span>(詳)</span></a>
        </div>
      </div>
      <!-- B008 -->
      <div :ref="(el) => addSectionRef(el, 1)">
        <ImageMap v-if="!!mobileMapBanner && !isVirtualShelfLayout" block-name="main-strategy" :image-url="mobileMapBanner.imageUrl" :map-data="mobileMapBanner.items" :banner-width-base="975" />
      </div>
      <!-- B009 -->
      <div v-if="hasSmallBannerSlide && !isVirtualShelfLayout" class="tw-w-full tw-border-b tw-border-solid tw-border-gray-100"><PromotionalHighlights /></div>
      <!-- A002 -->
      <div :ref="(el) => addSectionRef(el, 2)" class="banner-box"><CouponBanner v-if="!isVirtualShelfLayout" /></div>
      <!-- B010, B011 -->
      <div class="banner-box"><TabsProducts title="超划算限搶" :tabs="productCardSlide" product-data="home-gooddeal" :hidden-tab="true" /></div>
      <div class="banner-box"><TabsProducts title="新書焦點" :tabs="productCardSlides" tab-data="home-newbook-tags" product-data="home-newbook-products" /></div>
      <!-- B012, B013 -->
      <div :ref="(el) => addSectionRef(el, 3)" class="banner-box">
        <MemberBannerGroupComponent v-if="!isVirtualShelfLayout" title="會員獨享" :member-only="getMembersOnly" :slide-and-banner="getSlideAndFourBanner" />
      </div>
      <!-- B014 -->
      <div class="banner-box"><HotKeywordsComponent v-if="!isVirtualShelfLayout" title="熱門關鍵字" /></div>
      <!-- B015 -->
      <div :ref="(el) => addSectionRef(el, 4)" class="banner-box"><BestSellersOnlineComponent class="banner-box" title="網路暢銷榜" /></div>
      <!-- B016, B017 -->
      <div :ref="(el) => addSectionRef(el, 5)" class="banner-box"><ThemePlanningComponent v-if="!isVirtualShelfLayout" title="主題企劃" /></div>
      <!-- B018 -->
      <div class="banner-box"><TabsProducts title="限定商品" :tabs="limitedEditionGroup" product-data="home-unique" :hidden-tab="true" /></div>
      <!-- B019 -->
      <div class="banner-box"><TabsImages v-if="!isVirtualShelfLayout" title="獨家品牌" :tabs="exclusiveBrandGroup" /></div>
      <!-- B020 -->
      <div :ref="(el) => addSectionRef(el, 6)" class="banner-box"><StrongRecommendationComponent v-if="!isVirtualShelfLayout" title="強檔推薦" :tabs="strongRecommendation" /></div>
      <!-- B021 -->
      <div class="banner-box"><BankCardSlideComponent v-if="!isVirtualShelfLayout" title="銀行/行動支付優惠總覽" /></div>
      <!-- B022 -->
      <div class="banner-box"><StrikingBrands v-if="!isVirtualShelfLayout" title="注目品牌" /></div>
      <!-- B023 -->
      <div class="banner-box"><BrandListComponent v-if="!isVirtualShelfLayout" title="品牌列表" :items="getBrandList" /></div>
      <!-- B024 -->
      <div :ref="(el) => addSectionRef(el, 7)" class="banner-box"><TopicSelectionTabsComponent v-if="!isVirtualShelfLayout" title="話題選品" :items="topicSelectionTabs" /></div>
      <!-- B025 -->
      <div class="banner-box"><WideBannerComponent v-if="!isVirtualShelfLayout" title="橫幅腰帶" :items="wideBanner" /></div>
      <!-- B026 品類樓層 -->
      <div :ref="(el) => addSectionRef(el, 8)" class="banner-box"><ThreadGroup v-if="!isVirtualShelfLayout" /></div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, computed, onMounted, onBeforeUnmount, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useHead } from '@unhead/vue';
import { useHomeAdStore } from '@/stores/home-ad';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import BigSlideComponent from '@/components/layout/elements/mobile/big-slide-mobile-component.vue';
import ImageMap from '@/components/layout/elements/image-map.vue';
import TabsProducts from '@/components/layout/elements/mobile/tabs-products.vue';
import TabsImages from '@/components/layout/elements/mobile/tabs-images.vue';
import PromotionalHighlights from '@/components/layout/elements/mobile/promotional-highlights.vue';
import MemberBannerGroupComponent from '@/components/layout/elements/mobile/member-banner-group.vue';
import CouponBanner from '@/components/layout/elements/mobile/coupon-banner.vue';
import NavLinks from '@/components/layout/elements/mobile/nav-links.vue';
import HotKeywordsComponent from '@/components/layout/elements/mobile/hot-keywords.vue';
import BestSellersOnlineComponent from '@/components/layout/elements/mobile/best-sellers-online.vue';
import ThemePlanningComponent from '@/components/layout/elements/mobile/theme-planning.vue';
import StrongRecommendationComponent from '@/components/layout/elements/mobile/strong-recommendation.vue';
import BankCardSlideComponent from '@/components/layout/elements/mobile/bank-card-slide-component.vue';
import StrikingBrands from '@/components/layout/elements/mobile/striking-brands.vue';
import BrandListComponent from '@/components/layout/elements/mobile/brand-list.vue';
import TopicSelectionTabsComponent from '@/components/layout/elements/mobile/topic-selection-tabs.vue';
import WideBannerComponent from '@/components/layout/elements/mobile/wide-banner.vue';
import ThreadGroup from '@/components/layout/elements/mobile/thread-group.vue';
import setHead from '@/composables/ldjson/home';
import virtualShelf from '@/composables/utility/virtual-shelf';
import { preloadImage } from '@/helper/meta/preload-image';

const SMALL_SCREEN_HEIGHT = 667;
const homeAdStore = useHomeAdStore();
const { fetchHomeAd } = homeAdStore;
const homeAd2024Store = useHomeAd2024Store();
const { fetchHomeAd: fetchHomeAd2024 } = homeAd2024Store;
const {
  getBigSlide,
  getTopBanner,
  getEightLinks,
  getSmallBannerSlide,
  getProductCardSlides,
  getProductCardSlide,
  getHtmlMapBanner,
  getMembersOnly,
  getSlideAndFourBanner,
  getLimitedEditionGroup,
  getExclusiveBrandGroup,
  getStrongRecommendation,
  getBrandList,
  getTopicSelectionTabs,
  getWideBanner,
} = storeToRefs(homeAd2024Store);

const { isVirtualShelfLayout } = virtualShelf();
const $screen = inject('$screen');

const defaultFaceTree2024 = [
  // #0: B007, A001, B001, A004(tab-bar links)
  [homePageADTypeEnum.bigSlide, homePageADTypeEnum.eightLinks, homePageADTypeEnum.topBanner],
  // #1 (event): B008, B009
  [homePageADTypeEnum.htmlMapBanner, homePageADTypeEnum.smallBannerSlide],
  // #2: A002, B010, B011
  [homePageADTypeEnum.couponBanner, homePageADTypeEnum.productCardSlide, homePageADTypeEnum.productCardSlides],
  // #3: B012, B013, B014
  [homePageADTypeEnum.membersOnly, homePageADTypeEnum.slideAndFourBanner, homePageADTypeEnum.hotKeywords],
  // #4: B015 (fetchHomeAd)
  [homePageADTypeEnum.onlineLeaderboard],
  // #5: B016-B019
  [homePageADTypeEnum.newMainTopicProject, homePageADTypeEnum.threeBannerGroup, homePageADTypeEnum.limitedEditionGroup, homePageADTypeEnum.exclusiveBrandGroup],
  // #6: B020-B023
  [homePageADTypeEnum.strongRecommendation, homePageADTypeEnum.bankCardSlide, homePageADTypeEnum.strikingBrands, homePageADTypeEnum.brandList],
  // #7: B024-B025
  [homePageADTypeEnum.topicSelectionTabs, homePageADTypeEnum.wideBanner],
  // #8: B026
  [homePageADTypeEnum.newThreadGroup],
];

const props = defineProps({ scrollMode: { type: Boolean, default: false } });
const { scrollMode } = toRefs(props);

/** @const {boolean} hasSmallBannerSlide 確認是否有自訂活動 */
const hasSmallBannerSlide = computed(() => (getSmallBannerSlide.value?.items || []).length > 0);

/** @const {computed({})} eightLinks A001 導覽連結 */
const eightLinks = computed(() => (getEightLinks.value?.items || []).map((item, index) => ({ ...item, sort: index + 1 })));
/** @const {computed({})} topBannrImageSource 置頂 banner data */
const topBannrImageSource = computed(() => getTopBanner.value);
/** @const {computeed(boolean)} haveTopBanner 是否有置頂 banner */
const haveTopBanner = computed(() => !!topBannrImageSource.value?.image);

const mobileMapBanner = computed(() => getHtmlMapBanner.value?.mobile || null);
const productCardSlide = computed(() => (getProductCardSlide?.value?.items === undefined || getProductCardSlide?.value?.items.length === 0 ? [] : getProductCardSlide.value?.items || []));
const productCardSlides = computed(() => (getProductCardSlides?.value?.items === undefined || getProductCardSlides?.value?.items.length === 0 ? [] : getProductCardSlides.value?.items || []));
const limitedEditionGroup = computed(() => (getLimitedEditionGroup?.value?.items === undefined || getLimitedEditionGroup?.value?.items.length === 0 ? [] : getLimitedEditionGroup.value?.items || []));
const exclusiveBrandGroup = computed(() => (getExclusiveBrandGroup?.value?.items === undefined || getExclusiveBrandGroup?.value?.items.length === 0 ? [] : getExclusiveBrandGroup.value?.items || []));
const strongRecommendation = computed(() =>
  getStrongRecommendation.value?.items === undefined || getStrongRecommendation.value?.items.length === 0 ? [] : getStrongRecommendation.value?.items || [],
);
const topicSelectionTabs = computed(() => (getTopicSelectionTabs.value?.items === undefined || getTopicSelectionTabs.value?.items.length === 0 ? [] : getTopicSelectionTabs.value?.items || []));
const wideBanner = computed(() => (getWideBanner.value?.items === undefined || getWideBanner.value?.items.length === 0 ? [] : getWideBanner.value?.items || []));
const preloadImageSrc = computed(() => {
  // 小螢幕裝置（667以下）
  if ($screen.height <= SMALL_SCREEN_HEIGHT) {
    return getBigSlide.value?.items?.[0]?.image;
  }

  // 大螢幕裝置（667以上）的邏輯
  if ($screen.height > SMALL_SCREEN_HEIGHT) {
    // 有頂部橫幅時
    if (haveTopBanner.value) {
      return getBigSlide.value?.items?.[0]?.image;
    }
    // 沒有頂部橫幅時
    return mobileMapBanner.value?.imageUrl;
  }

  return null;
});
const metaInfo = computed(() => {
  if (preloadImageSrc.value) {
    preloadImage(preloadImageSrc.value);
  }
  return {};
});
useHead(metaInfo);

const sectionRefs = ref([]);
let observer = null;

const createObserver = () => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.value.indexOf(entry.target);
          // console.log('Intersecting index:', index, defaultFaceTree2024[index] || null);
          if (index === -1) return;
          if (index === 4) fetchHomeAd(defaultFaceTree2024[index]); // B015
          else fetchHomeAd2024(defaultFaceTree2024[index]);
          observer.unobserve(entry.target);
        }
      });
    },
    { rootMargin: '0px 0px 0px 0px', threshold: 0.25 },
  );

  sectionRefs.value.forEach((section) => {
    if (section) observer.observe(section);
  });
};

const addSectionRef = (el) => {
  if (el && !sectionRefs.value.includes(el)) sectionRefs.value.push(el);
};

setHead();

onMounted(() => {
  createObserver();
});

onBeforeUnmount(() => {
  if (observer) observer.disconnect();
});
</script>

<script>
export default { name: '2024Mobile' };
</script>

<style scoped>
.index-page {
  @apply tw-p-0 tw-mx-auto tw-relative;
}

.banner-box {
  @apply tw-w-full;
}

.top-banner {
  @apply tw-w-full tw-p-0;
  img {
    @apply tw-w-full;
  }
}

.fraud-prevention-box {
  @apply tw-p-4 tw-bg-gray-50;
  @media (max-width: 375px) {
    @apply tw-px-2;
  }
}
.fraud-prevention {
  @apply tw-rounded-lg tw-bg-white tw-px-4 tw-py-1 tw-text-sm;
  @apply tw-whitespace-pre tw-overflow-hidden;
  @media (max-width: 375px) {
    @apply tw-px-2;
  }
  a {
    @apply tw-no-underline tw-flex tw-items-center tw-justify-center;
    color: theme('colors.gold-500');
    span {
      color: theme('colors.gold-300');
      border-bottom: 1px solid theme('colors.gold-300');
    }
  }
  img {
    @apply tw-mr-[10px];
    @media (max-width: 375px) {
      @apply tw-mr-2;
    }
  }
}
</style>
