import { useRoute } from 'vue-router';
import { computed, inject, ref } from 'vue';
import { filter, pipe } from 'ramda';
import { storeToRefs } from 'pinia';
import { ClientJS } from 'clientjs';
import checkIsInlineApp from '@/helper/check-inline-app';

// store
import { useRetailSearchLogStore } from '@/stores/retail-search-log';

// service
import FingerprintService from '@/services/log/fingerprint-service';
import RetailEventService from '@/services/log/retail-event-service';

// helper
import { getGoogleAnalyticsClientId } from '@/helper/gtm';

// api
import postLog from '@/api/log/search-log-events';
import { isEmptyValue } from '@/helper/data-process';
import LocalStorageServices from '@/services/local-storage-services';

/**
 * Local Storage Key
 * @type {string}
 */
const localKeyVersion = '01';
export const attributionLocalKey = `retail_attr_${localKeyVersion}`;

/**
 * setToken
 * 將 token 存放在 ref & store
 * @param token
 * @param setToStore
 * @returns {(function(*): void)|*}
 */
const setToken = (token, setToStore) => (value) => {
  token.value = value;
  // set to store : 離開 search page 使用
  // > attribution token 使用，建議不要 cache，以免造成資料錯誤 / 流程混亂
  // > 所以使用 store > localstorage (僅在 step2 存在跳轉可能使用)
  setToStore(token);
};

export const attributionTokenSourceEnum = {
  cart: 'cart',
  search: 'search',
};
const attributionTokenParamName = {
  [attributionTokenSourceEnum.cart]: 'attr',
};
const getTokenFromMultiSource = (route, token, storeToken) => (from) => {
  // todo[check]: search 頁應該拿 token.value
  // todo[check ??? 為什麼沒寫入 step3 看起來正常]: 只有 step1 是 attr or store
  // todo[done]: step2 應該拿 store，step3 應該拿 localstorage
  const key = attributionTokenParamName[from];
  const sortedToken = checkIsInlineApp() === true ? [route.query[key], storeToken.value, token.value] : [storeToken.value, token.value];
  return pipe(
    filter((x) => !isEmptyValue(x)),
    (x) => x[0] || '',
  )(sortedToken);
};

/**
 * 清除 token
 *  - removeLocalToken => clear local storage (step2 used.)
 *  - clearAttributionToken => clear all token (step3 used.)
 */
const removeLocalToken = () => {
  const clientStorage = new LocalStorageServices(Storage, localStorage);
  clientStorage.remove(attributionLocalKey);
};
const clearAttributionToken = (setSearchToken) => () => {
  // clean `ref` params & store
  setSearchToken(null);

  // clean local storage
  removeLocalToken();
};

const getLocalToken = () => {
  const clientStorage = new LocalStorageServices(Storage, localStorage);
  return clientStorage.get(attributionLocalKey);
};
const setLocalToken = (getToken) => () => {
  const clientStorage = new LocalStorageServices(Storage, localStorage);
  return clientStorage.set(attributionLocalKey, getToken.value);
};

export function useRetailLogEvent() {
  /**
   * vue instance
   */
  const auths = inject('$auths');
  const route = useRoute();

  /**
   * store
   */
  const retailStore = useRetailSearchLogStore();
  const { setAttributionToken } = retailStore;
  const { getAttributionToken } = storeToRefs(retailStore);

  /**
   * user data
   */
  const fingerprintService = new FingerprintService(new ClientJS());
  const clientId = getGoogleAnalyticsClientId() || fingerprintService.get() || '';
  const user = auths?.user()?.uuid || '';

  /**
   * vue data
   */
  const attributionToken = ref('');
  const searchFilter = ref('');

  /**
   * A/B Testing Logging
   * */
  const retailLogService = new RetailEventService(postLog, clientId, auths?.getToken());

  /**
   * export methods
   */
  const setSearchToken = setToken(attributionToken, setAttributionToken);
  const setFilter = (input) => (searchFilter.value = input);

  /**
   * computed
   */
  const getSearchToken = computed(() => attributionToken.value);
  const getStoreToken = computed(() => getAttributionToken.value);
  const getMultiSourceToken = getTokenFromMultiSource(route, getSearchToken, getStoreToken);
  const getSearchFilter = computed(() => searchFilter.value);

  return {
    retailLogService,
    setSearchToken,
    searchToken: getSearchToken,
    getStoreToken,
    getAttributionToken: getMultiSourceToken,
    traceUser: clientId,
    uuid: user,

    // storage token control
    getLocalToken,
    setLocalToken: setLocalToken(getStoreToken),

    // 只刪 local -> step2 流程控制
    removeToken: clearAttributionToken(setSearchToken),
    // 全刪(local + store + instance)，step 3 -> ga 送出完成後不保留
    removeLocalToken,

    // filter
    setFilter,
    searchFilter: getSearchFilter,
  };
}
