<template>
  <div class="slide">
    <div class="index-page-tabs">
      <ul>
        <li v-for="(item, index) in tabs" :key="`tab-${index + 1}`">
          <router-link-usage :id="`home-activity-${index + 1}`" :link="item.link" :title="item.alt">{{ item.alt }}</router-link-usage>
        </li>
      </ul>
    </div>
    <div class="index-page-slide">
      <swiper v-if="!isEmptyValue(slides)" ref="swiperBigSlide" class="swiper swiperBigSlide" :options="swiperBigSlideOptions">
        <swiper-slide v-for="(item, index) in slides" :key="`type-2-${index}`" class="swiper-slide">
          <div class="big-slide-box">
            <a :id="`home-bbanner-${index + 1}`" :href="item.link" :title="item.alt" class="big-banner">
              <img class="swiper-lazy" :src="item.image" :alt="item.alt" :fetchpriority="index === 0 ? 'high' : 'low'" />
            </a>
            <div v-if="!$isEmpty(item.products)" class="hot-product-box">
              <div class="title">主打商品</div>
              <div v-for="(item2, index2) in item.products" :key="index2" class="product-list">
                <router-link-usage :id="`home-bproduct-${index + 1}-${index2 + 1}`" :link="item2.link" :title="item2.name" class="card">
                  <div class="card-title tw-col-span-2">{{ item2.name }}</div>
                  <lazy-webp-image :src="getImage(item2.id)" :alt="item2.name" :is-lazy="false" class="card-image tw-row-span-3"></lazy-webp-image>
                  <div class="tw-row-span-3">
                    <div class="card-description">
                      <div class="tw-line-clamp-2">{{ item2.title }}</div>
                    </div>
                    <div class="card-dollar">{{ priceFormatWithDollarSign(getPrice(item2.id)) }}</div>
                  </div>
                </router-link-usage>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div v-show="slides.length > 1" slot="button-prev" class="swiper-button-prev"></div>
        <div v-show="slides.length > 1" slot="button-next" class="swiper-button-next"></div>
        <div v-show="slides.length > 1" slot="pagination" class="swiper-pagination"></div>
      </swiper>
      <div v-else class="loading logo-loading">&nbsp;</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { map, reduce } from 'ramda';
import { useHead } from '@unhead/vue';
import { usePriceAndImageStore } from '@/stores/price-and-image';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import LazyWebpImage from '@/components/utility/image/lazy-webp-image.vue';
import { priceFormatWithDollarSign } from '@/helper/filter/price-format';
import { isEmptyValue } from '@/helper/data-process';
import { preloadImage } from '@/helper/meta/preload-image';
import { modifySwiperOptionForVisualTest } from '@/helper/swiper';

const priceStore = usePriceAndImageStore();
const { fetchPrice } = priceStore;
const { getPrices, getImages } = storeToRefs(priceStore);
const homeAdStore = useHomeAd2024Store();
const { getBigSlideTabs, getBigSlide } = storeToRefs(homeAdStore);
const tabs = computed(() => getBigSlideTabs.value?.items || []);
const slides = computed(() => getBigSlide.value?.items || []);

const metaInfo = computed(() => {
  if (!getBigSlide.value?.items) return {};
  preloadImage(getBigSlide.value?.items[0].image);
  // console.log(`[useHead][BigSlideComponent]: call preloadImage`);
  return {};
});
useHead(metaInfo);

const getProductPriceAndImages = () => {
  const ids = reduce((acc, item) => [...acc, ...map((product) => product.id || product.esliteSN, item.products || [])], [], slides.value).sort();
  if (ids.length > 0) fetchPrice({ productIds: ids });
};

const getImage = (id) => getImages.value[id]?.small || '';
const getPrice = (id) => getPrices.value[id];

const swiperBigSlide = ref(null);
const swiperBigSlideOptions = ref(
  modifySwiperOptionForVisualTest({
    init: true,
    loop: true,
    autoplay: { delay: 3000, disableOnInteraction: false },
    slidesPerView: 'auto',
    spaceBetween: 0,
    navigation: { nextEl: '.swiperBigSlide .swiper-button-next', prevEl: '.swiperBigSlide .swiper-button-prev' },
    pagination: { el: '.swiperBigSlide .swiper-pagination', clickable: true },
  }),
);

onMounted(() => {
  watch(slides, getProductPriceAndImages, { immediate: true });
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.index-page-tabs {
  @apply tw-w-full tw-h-[38px] tw-text-base tw-py-2 tw-mb-4;

  ul {
    @apply tw-p-0 tw-m-0 tw-flex tw-flex-row tw-justify-end;
  }
  li {
    @apply tw-h-4 tw-leading-4 tw-px-4 tw-py-0 tw-m-0 tw-flex tw-items-center;
    border-left: 1px solid theme('colors.gray-200');

    &:first-child {
      border-left: none;
    }
    &:last-child {
      @apply tw-pr-0;
    }
  }
  a {
    color: theme('colors.gray-500');
    &:hover {
      color: theme('colors.gold-500');
      text-decoration: none;
    }
  }
}

.index-page-slide:hover {
  .swiper-button-prev,
  .swiper-button-next {
    @apply tw-flex;
  }
}

.index-page-slide {
  @apply tw-w-[1044px] tw-max-w-[1044px] tw-h-[474px] tw-p-0 tw-m-0;
  /* outline: 1px solid blue; */
}

.big-slide-box {
  @apply tw-w-full tw-h-[474px] tw-p-0 tw-my-0 tw-mx-auto tw-rounded-lg tw-relative tw-overflow-hidden;

  .big-banner {
    @apply tw-w-full tw-h-full tw-block;
  }

  .title {
    @apply tw-text-base tw-font-medium tw-text-center;
    padding: 30px 0 19px;
    letter-spacing: 0.128px;
    color: theme('colors.black');
  }
}

.hot-product-box {
  @apply tw-w-[196px] tw-h-[454px] tw-p-0 tw-m-auto tw-absolute tw-inset-y-0 tw-right-0 tw-shrink-0;
  background: linear-gradient(rgba(255, 255, 255, 0) 0 40px, rgba(255, 255, 255, 1) 110px 100%), url('@/static/images/bg/big-slide-box-right-products-bg-stripe.svg') no-repeat top left,
    theme('colors.white');
  border: 1px solid theme('colors.gray-100');
  border-radius: 8px 8px 0px 0px;
}

.product-list {
  @apply tw-w-[156px] tw-mx-auto tw-my-0 tw-pb-3;
  border-bottom: 1px dashed theme('colors.gray-c');

  + .product-list {
    padding-top: 11px;
  }

  &:last-child {
    border-bottom: none;
  }

  .card {
    border: none;
    @apply tw-w-[156px] tw-p-0 tw-grid tw-gap-x-1 tw-gap-y-2 tw-no-underline tw-flex-col tw-items-center;
    grid-template-columns: repeat(2, minmax(0, 80px));
    grid-template-rows: repeat(4, minmax(0, 20px));
  }

  .card-title {
    @apply tw-w-full tw-p-0 tw-m-0 tw-text-sm tw-text-left tw-overflow-hidden tw-whitespace-nowrap;
    color: theme('colors.gray-800');
  }
  .card-image {
    @apply tw-max-w-20 tw-max-h-20 tw-pointer-events-none tw-object-contain;
    background-color: theme('colors.white');
  }
  .card-description {
    @apply tw-w-[76px] tw-h-[60px] tw-text-left;
    @apply tw-text-13px;
    color: theme('colors.gray-500');
  }
  .card-dollar {
    @apply tw-w-[76px] tw-h-[20px] tw-text-left;
    @apply tw-text-sm tw-font-bold;
    color: theme('colors.red-600');
  }
}

.swiper-pagination {
  opacity: 0.8;
}
</style>
