import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePriceStore } from '@/stores/price';
import { usePriceAndImageStore } from '@/stores/price-and-image.js';
import { usePriceQueueStore } from '@/stores/price-queue';
import { formatRuleTypeEnum } from '@/constant/product/product-price';
import { checkIsImageSnapshotTest } from '@/helper/check-image-snapshot-test';

export default function ({ id, useQueue = true, mode = 'price' }) {
  const useMode = ['price', 'price-and-image'].includes(mode) ? mode : 'price';
  const priceStore = useMode === 'price-and-image' ? usePriceAndImageStore() : usePriceStore();
  const priceQueueStore = usePriceQueueStore();
  const { getPrices, getPrePrices, getDiscountsOfTypes } = storeToRefs(priceStore);

  const isLoading = ref(true);
  /**
   * Computed
   */
  const prePrice = computed(() => getPrePrices.value[id]);
  const price = computed(() => getPrices.value[id]);
  const discountsOfTypes = computed(() => getDiscountsOfTypes.value[id] || {});
  const discountWording = computed(() => discountsOfTypes.value[formatRuleTypeEnum.wording]);
  const discountPriceOnly = computed(() => discountsOfTypes.value[formatRuleTypeEnum.priceOnly]);

  /**
   * Methods
   */
  const targetFetchPrice = ({ productIds }) => {
    if (useQueue || checkIsImageSnapshotTest()) {
      return priceQueueStore.fetchPriceByQueue({ productIds });
    }
    return priceStore.fetchPrice({ productIds });
  };
  const setLoading = () => {
    isLoading.value = true;
  };
  const completeLoading = () => {
    isLoading.value = false;
  };

  return {
    isLoading,
    getPrices,
    getPrePrices,
    getDiscountsOfTypes,
    prePrice,
    price,
    discountsOfTypes,
    discountWording,
    discountPriceOnly,
    targetFetchPrice,
    setLoading,
    completeLoading,
  };
}
