import { random } from '@/helper/random';
import { checkIsTest } from '@/helper/test/test-env';

export function checkIsImageSnapshotTest() {
  return import.meta.env.VITE_IS_IMAGE_SNAPSHOT_TEST === 'true';
}

export function randomIfNotTest(max = 10, min = 0) {
  if (checkIsImageSnapshotTest() || checkIsTest()) {
    return min;
  }
  return random(max, min);
}
