<template>
  <div v-if="!$inApp" ref="headerFixedWrapperRef" :class="{ 'tw-bg-white': isMemberProfileUrl }" class="header header-mobile" data-test-id="header">
    <!-- download app -->
    <div v-if="showComponent" title="APP下載" class="download-app" @click="appRedirectLink()">
      <img src="@/static/images/app-icon.webp" alt="誠品線上" />
      <div class="txt">下載APP首登，現領$100優惠券！</div>
      <div class="gold"><span>立即體驗</span></div>
    </div>

    <!-- search -->
    <HeaderSearchBar />
  </div>
  <div v-if="!$inApp" class="nbsp" :style="`height: ${height}px`">&nbsp;</div>
  <full-banner v-if="route.name === 'index'" />
</template>

<script setup>
import { ref, computed, onUnmounted, onBeforeMount, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Cookies from 'js-cookie';
import { useCartItemsStore } from '@/stores/cart-items';
import { useHomeAd2024Store } from '@/stores/home-ad-2024';
import { homePageADTypeEnum } from '@/constant/ad/homepage-ad-type';
import HeaderSearchBar from '@/components/layout/elements/mobile/header-searchbar.vue';
import FullBanner from '@/components/layout/full-banner.vue';
import { memberProfileStyle } from '@/constant/member-profile';

const route = useRoute();
const isMemberProfileUrl = computed(() => memberProfileStyle.includes(route.fullPath));
const COOKIE_KEY = 'eslite_app_download';
const COOKIE_DOMAIN = import.meta.env.VITE_TYPE === 'development' ? 'localhost' : '*.eslite.com';
const COOKIE_EXPIRATION = 1; // 1 天

const isLoaded = ref(false);
const headerFixedWrapperRef = ref(null);
const height = ref(60);

// store
const homeAdStore = useHomeAd2024Store();
const { fetchHomeAd } = homeAdStore;
const cartItemsStore = useCartItemsStore();
const { initCartQuantity, clearQuantity } = cartItemsStore;

const appRedirectLink = () => {
  console.log(`appRedirectLink`);
  // 設定 cookie 並隱藏元件
  Cookies.set(COOKIE_KEY, 'true', { expires: COOKIE_EXPIRATION, domain: COOKIE_DOMAIN });
  showComponent.value = false;
  height.value = 60;

  const ua = navigator.userAgent;
  let appRedirectLink = 'https://vip.eslite.com/esliteapp/tw/';
  if (ua.match(/(iPad|iPhone|iPod)/i)) appRedirectLink = 'https://apps.apple.com/tw/app/id1527231018';
  else if (ua.match(/Android/i)) appRedirectLink = 'https://play.google.com/store/apps/details?id=com.eslite.tw';

  window.open(appRedirectLink, '_blank');
};

// app download
const showComponent = ref(false);
const checkCookie = () => {
  // 如果 cookie 不存在，顯示元件
  if (!Cookies.get(COOKIE_KEY)) {
    showComponent.value = true;
    height.value = 112;
  } else {
    height.value = 60;
  }
};

onBeforeMount(() => {
  isLoaded.value = true;
});
onMounted(() => {
  initCartQuantity();
  // B007, B002, A001
  fetchHomeAd([homePageADTypeEnum.bigSlide, homePageADTypeEnum.logo, homePageADTypeEnum.eightLinks]);
  checkCookie();
});
onUnmounted(() => {
  clearQuantity();
});
</script>

<script>
export default { name: 'HeaderWith2024Mobile' };
</script>

<style scoped>
.header.header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.download-app {
  @apply tw-h-[52px] tw-px-4 tw-py-[6px] tw-text-[13px] tw-leading-[18px] tw-font-normal tw-grid tw-gap-2 tw-no-underline;
  grid-template-columns: auto 1fr auto;
  color: theme('colors.gray-900');
  background-color: theme('colors.white');
  img {
    @apply tw-w-10 tw-h-10 tw-rounded-lg;
  }
  .txt {
    @apply tw-grid tw-items-center tw-overflow-hidden;
  }
  .gold {
    @apply tw-grid tw-items-center;
    color: theme('colors.gold-500');
  }

  &:hover .gold span,
  &:active .gold span {
    border-bottom: 1px solid theme('colors.gold-500');
  }
}
</style>
