import { UAParser } from 'ua-parser-js';
import { botKeywords } from '../constant/ua';

const parser = new UAParser();

// 針對 iPad Pro 的 user agent 特別處理
const isSpecificiPadPro = () => {
  const ua = navigator.userAgent;
  return ua.includes('Macintosh') && 'ontouchstart' in window; // iPad Pro 使用桌面版的 user agent 但具有觸控能力
};

// 檢查是否為桌面設備
export const isDesktop = () => {
  const deviceType = parser.getDevice().type;

  // 特別處理 iPad Pro
  if (isSpecificiPadPro()) {
    return false; // iPad Pro 應該被判斷為平板
  }

  // 判斷為桌面設備的條件
  return !deviceType || deviceType === 'desktop';
};

// 檢查是否為平板設備
export const isTablet = () => {
  const deviceType = parser.getDevice().type;

  // 特別處理 iPad Pro
  if (isSpecificiPadPro()) {
    return true; // iPad Pro 應該被判斷為平板
  }

  // 判斷為平板設備的條件
  return deviceType === 'tablet';
};

// 檢查是否為手機設備
export const isMobile = () => {
  return parser.getDevice().type === 'mobile';
};

/**
 * isBot
 * @param {string} userAgent
 * @returns
 */
export const isBot = (userAgent) => {
  if (!userAgent && typeof window !== 'undefined' && window.navigator) userAgent = window.navigator.userAgent;

  // 網址 query string 帶上 lihgthouse=1 時同等 bot 模式
  if (typeof window !== 'undefined' && window.location.search.includes('lighthouse=1')) return true;

  if (typeof navigator !== 'undefined' && navigator.webdriver) return true;

  // 如果 userAgent 為 undefined，則視為不是 bot
  if (!userAgent) return false;

  // 將關鍵字組合成正則表達式
  const botPattern = new RegExp(botKeywords.join('|'), 'i');

  // 確保 userAgent 是小寫
  return botPattern.test(userAgent?.toLowerCase());
};

// 預設匯出所有方法
export default {
  isMobile,
  isTablet,
  isDesktop,
  isBot,
};
