import { defineStore } from 'pinia';
import { forEach, includes, keys, filter } from 'ramda';
import productPriceService from '@/services/product/product-price-service';
import { isEmptyValue, removeDuplicate, filterArrayByReference } from '@/helper/data-process';
import { formatImagePath } from '@/helper/image-formatter';
/**
 * State
 * @return {{prices: {}, isComplete: boolean}}
 */
const state = () => ({
  prePrices: {},
  prices: {},
  images: {},
  discountsOfTypes: {},
  isComplete: {},
  type: {},
});

/**
 * Getters
 */
const getPrePrices = (state) => state.prePrices;
const getPrices = (state) => state.prices;
const getImages = (state) => state.images;
const getDiscountsOfTypes = (state) => state.discountsOfTypes;
/** @const {object} getType { ebook: Boolean, preOrder: Boolean, restricted: Boolean } */
const getType = (state) => state.type;

const getters = {
  getPrePrices,
  getPrices,
  getImages,
  getDiscountsOfTypes,
  getType,
};

/**
 * Actions
 */
// reject already fetch
const rejectAlreadyFetch = (prices, products) => filter((product) => !includes(product, keys(prices)))(products);

// loading complete status
const setPriceCompleteStatus = ({ context, status }) => forEach((productId) => (context.isComplete[productId] = status));

// set Price and Discount
const setPriceAndDiscount = (context) =>
  forEach((product) => {
    context.prePrices[product.id] = product.originPrice;
    context.prices[product.id] = product.price;
    context.discountsOfTypes[product.id] = product.discountsOfTypes;
    context.images[product.id] = { small: formatImagePath(product.small), medium: formatImagePath(product.medium), large: formatImagePath(product.large) };
    context.type[product.id] = { restricted: product.restricted || false, preOrder: product.preOrder || false, ebook: product.ebook || false };
  });

export const actions = {
  /**
   * get price from api
   * @param {string[]} productIds id string[]
   * @returns productPriceService
   */
  fetchPrice({ productIds }) {
    if (isEmptyValue(productIds)) return;

    // 過濾重複
    productIds = removeDuplicate(productIds);
    // 過濾已在列表中
    productIds = filterArrayByReference(Object.keys(this.isComplete), productIds);
    if (isEmptyValue(productIds)) return;

    // remove already fetch item
    const filteredProductIds = rejectAlreadyFetch(this.prices, productIds);

    // reject empty
    if (isEmptyValue(filteredProductIds)) return;

    // set loading status
    setPriceCompleteStatus({ context: this, status: false })(filteredProductIds);

    // eslint-disable-next-line consistent-return
    return productPriceService
      .getPriceAndImage(filteredProductIds)
      .then(setPriceAndDiscount(this))
      .then(() => setPriceCompleteStatus({ context: this, status: true })(filteredProductIds));
  },
};

export const usePriceAndImageStore = defineStore('priceAndImage', {
  state,
  getters,
  actions,
});
