import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { forEach, map } from 'ramda';
import getHomeAds from '@/api/ad/home-page-2024';
import { isFunction, rejectEmpty } from '@/helper/data-process';
import homePageAdFormatter from '@/helper/ad/home-page-2024-formatter';
import { toCamelCase } from '@/helper/filter/string';
import { homePageAD, homePageADKeyEnum, homePageADMappingEnumWithNewIndex } from '@/constant/ad/homepage-ad-type';

/** @const {function} createGetters 依照status建立 getter */
const createGetters = (keys) =>
  keys.reduce((getters, key) => {
    getters[`get${toCamelCase(key)}`] = (context) => context[key];
    return getters;
  }, {});

/** @const {array} homePageADKeys 依照 constant 中首頁改版的內容建立 key array */
const homePageADKeys = homePageAD.filter((v) => v.mod === 'index2024' && v.key !== '').map((v) => v.key);

/** @const {object} homePageADState 依照 homePageADKeys 中的 key 建立 key object 用於塞入預設 state 中 */
const homePageADState = () =>
  homePageADKeys.reduce((rtn, v) => {
    rtn[v] = {};
    return rtn;
  }, {});

/**
 * actions
 */
// 整理資料部分
const getFormatter = (types) => homePageAdFormatter[`format${homePageADMappingEnumWithNewIndex[types]}`];
const findAd = (type, data) => data[type]?.content || data[type];
const filterExisted =
  () =>
  ({ data }) =>
    data;
const processAd = (data) => {
  return map((types) => {
    try {
      if (Object.keys(findAd(types, data)).length === 0) return { type: types };
      return isFunction(getFormatter(types)) ? getFormatter(types)(findAd(types, data)) : {};
    } catch (error) {
      return { type: types };
    }
  }, Object.keys(data));
};

/** @const {object} defaultHomeAdState 預設 state */
export const defaultHomeAdState = {
  // 用來判斷是否重取
  timestamp: new Date(),
  ...homePageADState(),
};

export const useHomeAd2024Store = defineStore('homeAd2024', {
  state: () => cloneDeep(defaultHomeAdState),
  getters: {
    getTimeStamp: (context) => context.timestamp,
    ...createGetters(homePageADKeys),
  },
  actions: {
    // 取得資料部分
    fetchHomeAd(displayItems = []) {
      // console.log(`fetchHomeAd2024`, displayItems);
      const adTypes = displayItems.map((item) => `slot_type=${item}`).join('&');
      const setAd = (data) => forEach((ad) => this.$patch({ [homePageADKeyEnum[ad.type]]: ad }), data);
      const updateTime = () => (this.timestamp = new Date());

      return getHomeAds(adTypes)
        .then(filterExisted(displayItems))
        .then(processAd)
        .then(rejectEmpty)
        .then(setAd)
        .then(updateTime)
        .then(() => true)
        .catch((e) => e);
    },
  },
});
