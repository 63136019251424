import { ref } from 'vue';
import { isProtectedPage } from '@/helper/protected-page';
import { createRouteMiddleware } from '@/plugins/route/middleware';
import { getPosition } from '@/helper/scroll';
import { setHistory } from '@/helper/url/history';
import { delPreloadImage } from '@/helper/meta/preload-image';
import { useHistoryStore } from '@/stores/history';
import { useLayoutErrorStore } from '@/stores/layout-error';
import { clearMeta } from '@/helper/ldjson/seo';

export default ({ pluginContext, router, isHistoryPopRef, pinia }) => {
  const isFirstLoad = ref(true);

  const routeMiddleware = createRouteMiddleware({ pluginContext, isFirstLoad, pinia });
  router.beforeEach(async (to, from, next) => {
    // await clearMeta(); // 清除 LD+JSON 及 og graph meta
    const historyStore = useHistoryStore();
    const layoutErrorStore = useLayoutErrorStore();
    historyStore.setIsHistoryPop(isHistoryPopRef.value);
    isHistoryPopRef.value = false;
    layoutErrorStore.clearError();
    const position = getPosition();
    setHistory({ ...from, ...position });
    routeMiddleware(to, from, next);
    // 檢查是否有 link preload image tag
    delPreloadImage();
    next();
  });

  // router.beforeResolve(async (to, from) => {
  //   const fromPath = from.path;
  //   const toPath = to.path;
  // });

  router.afterEach(async (to, from) => {
    const fromPath = from.path;
    const toPath = to.path;
    const needRefresh = isProtectedPage(toPath) !== isProtectedPage(fromPath);
    if (isFirstLoad.value) {
      isFirstLoad.value = false;
      return;
    }

    // 限制頁與非限制頁切換，refresh頁面，限制第三方script載入
    if (needRefresh && fromPath !== toPath) {
      console.log(`plugins needRefresh reload`);
      // window.location.reload();
      window.location.href = to.fullPath || toPath;
    }
  });
};
