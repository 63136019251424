import axios from 'axios';
import { isEmptyValue } from '@/helper/data-process';

const PRICE_API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_PRODUCT_PRICE}`;
const PRICE_AND_IMAGE_API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_PRODUCT_PRICE_AND_IMAGE}`;

export const fetchProductPrice = (id) => {
  if (isEmptyValue(id)) {
    return new Promise(function (resolve) {
      resolve({});
    });
  }

  return axios({
    method: 'get',
    url: PRICE_API_PATH.replace(':productId', id),
  });
};

/**
 * fetch Product Price And Image
 * @param {string} ids ids 若多筆id則逗號分割 ex:11111,22222,33333
 * @returns
 */
export const fetchProductPriceAndImage = (ids = '') => {
  if (isEmptyValue(ids)) {
    return new Promise(function (resolve) {
      resolve({});
    });
  }

  return axios({
    method: 'get',
    url: `${PRICE_AND_IMAGE_API_PATH}/${ids}`,
  });
};
