import clickOutside from '@/plugins/directives/click-outside';
import imageOnError from '@/plugins/directives/image-on-error';
import gtag from '@/plugins/tracking/gtag';
import gtmPlugin from '@/plugins/tracking/gtm';
import authPlugin from '@/plugins/auth';
import axiosPlugin from '@/plugins/axios';
import cryptoUtilityPlugin from '@/plugins/crypto-utility';
import '@/plugins/styles';
import '@/plugins/day';
import helper from '@/plugins/helper';
import routePlugin from '@/plugins/route';
import veeValidatePlugin from '@/plugins/vee-validate';
import vueFloating from '@/plugins/vue-floating';
import vueLazyload from '@/plugins/vue-lazyload';
import head from '@/plugins/head';
import webpSupportChecker from '@/plugins/webp-support-checker';
import windowResize from '@/plugins/window-resize';
import inApp from '@/plugins/in-app';
import localization from '@/plugins/localization';
import { isBot } from '@/helper/ua';

const plugins = [
  axiosPlugin,
  clickOutside,
  cryptoUtilityPlugin,
  isBot() ? null : gtag,
  isBot() ? null : gtmPlugin,
  helper,
  imageOnError,
  localization,
  routePlugin,
  veeValidatePlugin,
  vueFloating,
  vueLazyload,
  head,
  webpSupportChecker,
  windowResize,
  inApp,
];

export const addPlugins = async (context) => {
  console.log(`[isbot] main ${isBot()}`);
  plugins.forEach((plugin) => (plugin ? plugin(context) : null));
  await authPlugin(context);
};
