import { checkIsImageSnapshotTest } from '@/helper/check-image-snapshot-test';

const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

export function getSwiperGlobalOptions() {
  return isTouchDevice()
    ? {}
    : {
        // 防止桌機 click 變成 swipe
        threshold: 20,
      };
}

export function modifySwiperOptionForVisualTest(swiperOption) {
  if (!checkIsImageSnapshotTest()) return swiperOption;
  swiperOption.initialSlide = 0;
  swiperOption.autoplay = false;
  return swiperOption;
}
