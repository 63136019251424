<template>
  <swiper ref="controlledSwiper" :options="swiperOptions" class="swiper" @swiper="onSwiper">
    <swiper-slide v-for="(item, index) in items" :key="item.id">
      <RouterLinkUsage :id="`${idData}-${index + 1}`" :link="item.link" :title="item.alt" class="slide-banner-img">
        <img class="swiper-lazy" :src="item.image" :alt="item.alt" />
      </RouterLinkUsage>
    </swiper-slide>
    <div v-show="items.length > 1" class="swiper-button-prev"></div>
    <div v-show="items.length > 1" class="swiper-button-next"></div>
    <div v-show="items.length > 1" slot="pagination" class="swiper-pagination"></div>
  </swiper>
</template>

<script setup>
import { ref, toRefs } from 'vue';
import RouterLinkUsage from '@/components/utility/router-link-usage.vue';
import { modifySwiperOptionForVisualTest } from '@/helper/swiper';

const props = defineProps({
  items: { type: Array, default: () => [] },
  idData: { type: String, default: '' },
});

const { items, idData } = toRefs(props);

const controlledSwiper = ref(null);
const swiperInstance = ref(null);

const onSwiper = (swiper) => (swiperInstance.value = swiper);

const swiperOptions = modifySwiperOptionForVisualTest({
  slidesPerView: 1,
  spaceBetween: 16,
  slidesPerGroup: 1,
  loop: true,
  autoplay: { delay: 3000, disableOnInteraction: false },
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
  pagination: { el: '.swiper-pagination', clickable: true },
});
</script>

<style scoped>
@import url('@/assets/global-style/swiper.css');

.swiper {
  @apply tw-w-full tw-h-full tw-rounded-lg;
}

.slide-banner-img {
  @apply tw-h-full;

  img {
    @apply tw-w-full tw-h-full tw-object-cover tw-object-center;
  }
}
</style>
