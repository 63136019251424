<template>
  <div class="product-image e-square" :title="alt" @click="$emit('click')" @mouseover="$emit('mouseover')">
    <!-- 正常-->
    <div v-if="!restricted" class="item-image-wrap e-aspect-ratio-inner" :title="alt">
      <slot name="cover">
        <div class="gray-cover"></div>
      </slot>
      <lazy-webp-image class="product webp-image" :src="src" :alt="alt" :need-preload="needPreload" :error="errorImg" :loading="loadingImg"></lazy-webp-image>
    </div>
    <!-- 成人標示-->
    <div v-else class="item-image-wrap e-aspect-ratio-inner tw-bg-gray-f4">
      <slot name="image-top"></slot>
      <image-wait-loaded :is-skeleton-image="isSkeletonImage"><img :src="restrictedImg" /></image-wait-loaded>
    </div>
    <div v-if="isPreOrder" class="label">預購</div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import ImageWaitLoaded from '@/components/utility/image/image-wait-loaded.vue';
import LazyWebpImage from '@/components/utility/image/lazy-webp-image.vue';
import { useProductImageRestrict } from '@/composables/product/product-image-restrict';
import { productRestricted } from '@/constant/images-path';

const props = defineProps({
  alt: { type: String, default: '' },
  src: { type: String, default: '' },
  isAdult: { type: Boolean, default: false },
  disableLimit: { type: Boolean, default: false },
  isSkeletonImage: { type: Boolean, default: false },
  isPreOrder: { type: Boolean, default: false },
  errorImg: { type: String, default: '' }, // 用於特殊尺寸時的 lazy error
  loadingImg: { type: String, default: '' }, // 用於特殊尺寸時的 lazy loading
  restrictedImg: { type: String, default: productRestricted }, // 用於特殊尺寸時的成人標示
  needPreload: { type: Boolean, default: false },
});

const { alt, src, isAdult, disableLimit, isSkeletonImage, isPreOrder, errorImg, loadingImg, restrictedImg } = toRefs(props);
const { restricted } = useProductImageRestrict({ disableLimit, isAdult });
</script>

<script>
export default { name: 'ProductLazyImage' };
</script>

<style scoped>
.product-image,
.item-image-wrap {
  @apply tw-w-full tw-max-w-full tw-max-h-full tw-text-center;
}
.product-image {
  @apply tw-relative;
}
.gray-cover {
  @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-rounded-lg tw-pointer-events-none;
  background-color: rgba(0, 0, 0, 0.03);
}
.item-image-wrap {
  @apply tw-rounded-lg;
  :deep(img) {
    @apply tw-w-full tw-h-full tw-object-contain tw-object-center tw-rounded-lg;
  }
}
.label {
  @apply tw-w-full tw-h-6 tw-leading-6 tw-text-center tw-text-sm tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-opacity-80; /* tw-rounded-b-lg */
  background-color: theme('colors.gold-500');
  color: theme('colors.white');
}
</style>
