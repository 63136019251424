/** @const {object[]} botKeywords 幾個 bot 的關鍵字 */
export const botKeywords = [
  // AI2
  'ai2bot',

  // Amazon
  'amazonbot',

  // Anthropic
  'anthropic-ai',
  'claude-web',
  'claudebot',

  // Apple
  'applebot',
  'applebot-extended',

  // ByteDance
  'bytespider',

  // Common Crawl
  'ccbot',

  // DataForSeo
  'dataforseobot',

  // Diffbot
  'diffbot',

  // Google
  'googleother',
  'googleother-image',
  'googleother-video',
  'google-extended',

  // Hive AI
  'imagesiftbot',

  // Huawei
  'petalbot',

  // Meta
  'facebookbot',
  'meta-externalagent',

  // OpenAI
  'gptbot',
  'oai-searchbot',

  // Perplexity
  'perplexitybot',

  // Timpi
  'timpibot',

  // Velen.io
  'velenpublicwebcrawler',

  // Webz.io
  'omgili',
  'omgilibot',
  'webzio-extended',

  // You.com
  'youbot',

  // Zyte
  'scrapy',

  // global
  'bot',
];
