import { join, map, mapObjIndexed } from 'ramda';
import { fetchProductPrice, fetchProductPriceAndImage } from '@/api/product/product-price';
import { formatRuleTypeEnum } from '@/constant/product/product-price';
import { leastBuyCountTypeEnum } from '@/constant/product';
import { formatDiscountLevel, formatDiscountRange } from '@/helper/filter/discount';

const priceFormatter = {
  [formatRuleTypeEnum.wording]: formatDiscountLevel,
  [formatRuleTypeEnum.priceOnly]: formatDiscountRange,
};

const formatterParamsGetter = {
  [formatRuleTypeEnum.wording]: (data) => ({ type: data?.discount_type, range: data?.discount_range }),
  [formatRuleTypeEnum.priceOnly]: (data) => data?.discount_range || data?.range,
};

const formatDsicount = (product, formatterType) => priceFormatter[formatterType](formatterParamsGetter[formatterType](product));
const formatDsicountForAllTypes = (product) => mapObjIndexed((formatterType) => formatDsicount(product, formatterType), formatRuleTypeEnum);
const format = ({ data }) =>
  map(
    (product) => ({
      id: product.product_guid || product.guid,
      status: product?.product_button_status,
      originPrice: product?.final_price,
      price: product?.retail_price,
      type: product?.type || '',
      range: product?.range,
      discountsOfTypes: formatDsicountForAllTypes(product),
      leastBuyCountType: product?.least_buy_count_type || leastBuyCountTypeEnum.groupCount,
      leastBuyCountValue: product.least_buy_count_value !== undefined ? product.least_buy_count_value : 1,
    }),
    data,
  );

const formatWithPriceAndImage = ({ data }) =>
  map(
    (product) => ({
      id: product.eslite_sn || product.isbn,
      // status: product?.product_button_status,
      originPrice: product?.final_price,
      price: product?.retail_price,
      type: product?.discount_type || '',
      range: product?.discount_range,
      discountsOfTypes: formatDsicountForAllTypes(product),
      // leastBuyCountType: product?.least_buy_count_type || leastBuyCountTypeEnum.groupCount,
      // leastBuyCountValue: product.least_buy_count_value !== undefined ? product.least_buy_count_value : 1,
      small: product?.small_path,
      medium: product?.medium_path,
      large: product?.large_path,
      restricted: product?.restricted,
      preOrder: product?.preorder,
      ebook: product?.is_ebook,
    }),
    data || [],
  );

const getPrices = async (products) => {
  // 將 id 變成 `idA, idB, idC` 格式後，呼叫 api
  const params = join(',', products);
  if (!params) return Promise.resolve({});
  // eslint-disable-next-line prettier/prettier
  const rtn = await fetchProductPrice(params).then((response) => format(response));
  return rtn;
};

const getPriceAndImage = async (products) => {
  // 將 id 變成 `idA, idB, idC` 格式後，呼叫 api
  const params = join(',', products).replace(/ /g, '');
  if (!params) return Promise.resolve({});
  const rtn = await fetchProductPriceAndImage(params).then((response) => formatWithPriceAndImage(response));
  return rtn;
};

export default {
  getPrices,
  getPriceAndImage,
};
