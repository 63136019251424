import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Taipei');
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const dateTimeFormatter = 'YYYY/MM/DD HH:mm:ss';
const dateFormatter = 'YYYY/MM/DD';
const timeFormatter = 'HH:mm:ss';
const orderStatusDateTimeFormatter = 'MM-DD HH:mm';
const todayHourFormatter = 'YYYYMMDDHH';

/**
 * datetime format (YMD hms)
 * @param {datetime|string} d datetime
 * @returns {string} YYYY/MM/DD HH:mm:ss
 */
export const dateTimeFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(dateTimeFormatter));

/**
 * datetime format (YMD hms)
 * @param {datetime|string} d datetime
 * @param {string} f format string
 * @returns {string} datetime string
 */
export const dateTimeFormatWithCustom = (d, f = dateTimeFormatter) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(f));

/**
 * datetime format (YMD)
 * @param {datetime|string} d datetime
 * @returns {string} YYYY/MM/DD
 */
export const dateFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(dateFormatter));

/**
 * datetime format (hms)
 * @param {datetime|string} d datetime
 * @returns {string} HH:mm:ss
 */
export const timeFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(timeFormatter));

/**
 * datetime format (MM-DD HH:mm)
 * @param {datetime|string} d datetime
 * @returns {string} MM-DD HH:mm
 */
export const orderStatusDateTimeFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(orderStatusDateTimeFormatter));

/**
 * filter year
 * @const {string} datetime string
 * @return {string} year
 * */
export const year = (d) => dayjs(d).format('YYYY');

/**
 * filter month
 * @const {string} datetime string
 * @return {string} month
 * */
export const month = (d) => dayjs(d).format('MM');

/**
 * filter date
 * @const {string} datetime string
 * @return {string} date
 * */
export const day = (d) => dayjs(d).format('DD');

/**
 * filter hour
 * @const {string} datetime string
 * @return {string} hour
 * */
export const hour = (d) => dayjs(d).format('HH');

/**
 * convert 20200101 to 2020/01/01
 * @param  {string} date YYYYMMDD
 * @return {string} date YYYY/MM/DD
 */
export const dateYmd = (date) => {
  if (!date) return '';
  return `${date.slice(0, 4)}/${date.slice(4, 6)}/${date.slice(6)}`;
};

/**
 * convert 151757 to 15:17:57 (部分只有時分秒的)
 * @param  {string} time HHmmss
 * @return {string} time HH:mm:ss
 */
export const timeHms = (time) => {
  if (!time) return '';
  return `${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4)}`;
};

/**
 * 判斷轉為日期時是否為 Invalid Date
 * @param {*} d 日期或被轉換的值
 * @returns {boolean}
 */
// eslint-disable-next-line no-restricted-globals
export const isInvalid = (d) => isNaN(new Date(d));

/**
 * 判斷時間是否在期限內
 * @param {string} expired datetime string 期限
 * @param {string} checkTime datetime string 判斷時間 or 目前時間
 * @return {boolean}
 */
export const checkOnTime = (expired = '', checkTime = '') => {
  if (expired === '') return false;
  return +new Date(checkTime) > +new Date(expired);
};

/**
 * 判斷現在時間是否超過期限
 * @param {deadlineStr} deadlineStr 期限時間 (字串) YYYY-MM-DD HH:mm:ss
 * @return {boolean} 超過時限(true) / 還未超過時限(false)
 */
export const checkDeadline = (deadlineStr = '') => dayjs().isAfter(dayjs(deadlineStr));

export const checkDayTimeBetween = (start, end, check) => dayjs(check).isBetween(start, end);

/**
 * 取得Ｎ分鐘後時間
 * @param {number} minutes 分鐘
 * @return {Date}
 */
export const inFifteenMinutes = (minutes = 10) => new Date(new Date().getTime() + minutes * 60 * 60 * 1000);

/**
 * 取得Ｎ小時後時間
 * @param {number} hour 小時
 * @return {Date}
 */
export const inFifteenHours = (hour = 1) => new Date(new Date().getTime() + hour * 60 * 60 * 1000);

/**
 * 取得當前日期
 * @return {string}
 */
export const getCurrentDateTime = (formatter) => dayjs(new Date()).format(formatter || dateTimeFormatter);

/**
 * datetime format (YYYYMMDDHH) 取得目前時間 (年月日時)
 * @returns {string} YYYYMMDDHH
 */
export const getCurrentDateAndHour = () => getCurrentDateTime(todayHourFormatter);

/**
 * 取得當前 Taipei 日期
 * @return {string}
 */
export const getCurrentTaipeiDateTime = (formatter) => dayjs.tz().format(formatter || dateTimeFormatter);

/**
 * 取得當前年月日
 * @returns {string} YYYYMMDD
 */
export const getCurrentUTCDateInYYYYMMDD = () => {
  const d = new Date();
  const year = d.getUTCFullYear();
  const month = `0${d.getUTCMonth() + 1}`.slice(-2);
  const day = `0${d.getUTCDate()}`.slice(-2);
  return `${year}${month}${day}`;
};

/**
 * 取得當前年月日時分秒
 * @returns {string} YYYYMMDDHHmmss
 */
export const getCurrentUTCDateInYYYYMMDDHHmmss = () => {
  const d = new Date();
  const year = d.getUTCFullYear();
  const month = `0${d.getUTCMonth() + 1}`.slice(-2);
  const day = `0${d.getUTCDate()}`.slice(-2);
  const hour = `${d.getUTCHours() + 8}`;
  const min = `${d.getUTCMinutes()}`;
  const sec = `${d.getUTCSeconds()}`;
  return `${year}${month}${day}${hour}${min}${sec}`;
};

export default dayjs;
