/**
 * promise delay callback
 * @param {*} res any response data
 * @param {number} timer delay time
 * @returns Promise
 */

export const delayCallback = (res = () => {}, timer = 300) => new Promise((resolve) => setTimeout(() => resolve(res), timer));

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
