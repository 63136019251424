import VueGtag from 'vue-gtag';
import { isBot } from '@/helper/ua';

export default ({ app }) => {
  if (isBot()) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      console.log('[gtag] Tracking disabled for bots');
    };
    return false;
  }

  app.use(VueGtag, {
    config: {
      id: import.meta.env.VITE_GTAG_CODE,
      deferScriptLoad: true,
      params: {
        anonymize_ip: true,
        send_page_view: false,
      },
    },
  });
  return true;
};
