<template>
  <div class="full-wrapper">
    <cart-header></cart-header>
    <router-view :key="$route.path" />
    <Loading v-if="getLoadingStatus && !getWaitStatus"></Loading>
    <Wait v-else-if="getWaitStatus"></Wait>

    <TabBar2024Mobile v-if="$screen.isMobileSize && !$inApp" />
  </div>
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/stores/loading';
import CartHeader from '@/components/layout/cart-header.vue';
import Loading from '@/components/utility/loading.vue';
import Wait from '@/components/utility/wait.vue';
import TabBar2024Mobile from '@/components/layout/tab-bar.vue';
import { getDefaultMeta } from '@/helper/meta';

useHead(getDefaultMeta());
const loadingStore = useLoadingStore();
const { getLoadingStatus, getWaitStatus } = storeToRefs(loadingStore);
</script>

<script>
export default { name: 'CartLayout' };
</script>

<style scoped>
.full-wrapper {
  min-height: 100vh;
  background-color: theme('colors.gray-50');
  padding-bottom: 1rem;
}
</style>
